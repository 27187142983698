import { $authHost, $host } from "./index";
import { jwtDecode } from "jwt-decode";

export const registration = async (name, phoneNumber) => {
    const { data } = await $host.post('api/user/registration', { name, phoneNumber, role: 'USER' })
    localStorage.setItem('token', data.token)
    return jwtDecode(data.token)
}

export const loginEmployee = async (phoneNumber, password) => {
    const { data } = await $host.post('api/user/loginEmployee', { phoneNumber, password })
    localStorage.setItem('token', data.token)
    return jwtDecode(data.token)
}
export const loginUser = async (phoneNumber) => {
    try {
        const { data } = await $host.post('api/user/loginUser', { phoneNumber })
        localStorage.setItem('token', data.token)
        return jwtDecode(data.token)
    } catch (error) {
        console.log(error.message);
    }

}

export const check = async () => {
    try {
        const { data } = await $authHost.get('api/user/auth')
        localStorage.setItem('token', data.token)
        return jwtDecode(data.token)
    } catch (error) {
        console.log(error.message);
    }

}

export const createUser = async (user) => {
    const { data } = await $authHost.post('api/user/create', user)
    return data
}

export const deleteUser = async (user) => {
    const { data } = await $authHost.post('api/user/delete', user)
    return data
}

export const getAllUsers = async (role) => {
    const { data } = await $authHost.get('api/user/getAll', { params: { role } })
    return data
}

export const getOne = async (phoneNumber) => {
    const { data } = await $host.post('api/user/getOne', { phoneNumber })
    return data
}

export const getOneByPhone = async (phoneNumber) => {
    const { data } = await $host.post('api/user/getOneByPhone', { phoneNumber })
    return data
}

export const changePoints = async (points) => {
    const { data } = await $authHost.post('api/user/changePoints', points)
    return data
}

export const payByPoints = async (points) => {
    const { data } = await $authHost.post('api/user/payByPoints', points)
    return data
}

export const getPoints = async (userId) => {
    const { data } = await $host.get('api/user/getPoints', { params: { userId } })
    return data
}

export const blockUser = async (blockInfo) => {
    console.log(blockInfo);
    const { data } = await $authHost.post('api/user/blockUser', blockInfo)
    return data
}
export const unblockUser = async (phoneNumber) => {
    const { data } = await $authHost.post('api/user/unblockUser', phoneNumber)
    return data
}
export const getBlockedUsers = async () => {
    const { data } = await $host.get('api/user/getBlockedUsers')
    return data
}

export const getSMSCredentials = async () => {
    const { data } = await $host.get('api/user/sms-credentials');
    return data;
};