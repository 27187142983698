import User from './pages/user/User';
import Driver from './pages/driver/Driver';
import Admin from './pages/admin/Admin';
import { ADMIN_ROUTE, USER_ROUTE, DRIVER_ROUTE, DISPETCHER_ROUTE } from './utils/consts';
import dispetcher from './pages/dispetcher/dispetcher';

export const adminRoutes = [
    {
        path: ADMIN_ROUTE,
        Component: Admin
    }    
]

export const driverRoutes = [
    {
        path: DRIVER_ROUTE,
        Component: Driver
    }    
]

export const dispetcherRoutes = [
    {
        path: DISPETCHER_ROUTE,
        Component: dispetcher
    }    
]



export const publicRoutes = [
    {
        path: USER_ROUTE,
        Component: User
    },
]