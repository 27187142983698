import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import Layout from "./components/layout/Layout";
import PrivacyPolicy from "./pages/privacyPolicy/PrivacyPolicy";
import Error from "./pages/error/Error";
import { publicRoutes, adminRoutes, driverRoutes, dispetcherRoutes } from "./routes";
import { PRIVACYPOLICY_ROUTE, ERROR_ROUTE } from "./utils/consts";
import {
  selectRole,
  changeId,
  changeName,
  changeNumber,
  changeRole,
  changeBonuces
} from './features/user/userSlice';
import { check, getOneByPhone } from "./http/userApi";


function App() {
  const role = useSelector(selectRole);
  const dispatch = useDispatch()


  useEffect(() => {
    try {
      check().then(data => {
        if (data) {
          getOneByPhone(data.phoneNumber).then(data => {
            if (data.isBlocked) {
              localStorage.clear();
              return;
            }
            dispatch(changeBonuces(data.points))
          })
          dispatch(changeId(data.id))
          dispatch(changeName(data.name))
          dispatch(changeNumber(data.phoneNumber))
          dispatch(changeRole(data.role))
        }

      })
    } catch (error) {
      console.log(error)
    }
  }, [])

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route key='/privacyPolicy' path='/privacyPolicy' element={<PrivacyPolicy />} />
          {publicRoutes.map(({ path, Component }) => {
            return <Route key={path} path={path} element={<Component />} />
          })}
          {adminRoutes.map(({ path, Component }) => {     //role === "ADMIN" && 
            return <Route key={path} path={path} element={<Component />} />
          })}
          {driverRoutes.map(({ path, Component }) => {    //role === "DRIVER" && 
            return <Route key={path} path={path} element={<Component />} />
          })}
          {dispetcherRoutes.map(({ path, Component }) => {    
            return <Route key={path} path={path} element={<Component />} />
          })}
          <Route key={ERROR_ROUTE} path={ERROR_ROUTE} element={<Error />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
