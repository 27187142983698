import React, { useState, useEffect } from 'react';
import styles from './dispetcher.module.css'
import { getOneByPhone, changePoints } from '../../http/userApi';
import { deleteOrder, getAllByUser, updateOrder } from '../../http/ordersApi';
import { createCacheReserved } from '../../http/cacheReservedApi';
import { getAllSet } from '../../http/settingsApi';
import moment from 'moment';
import Modal from '../../components/UI/Modal/Modal';
import { Link } from 'react-router-dom';
import { getAllStops } from '../../http/stopsApi';

const Dispetcher = () => {

    const [number, setNumber] = useState('')
    const [searchResults, setSearchResults] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [modalMessage, setModalMessage] = useState('')
    
    const [stopsToChange, setStopsToChange] = useState([])
    
    const [orderToChange, setOrderToChange] = useState({
        comment:'',
        seats:'',
        startStop:{name:''}
    })
    const [UnSuccessValue, setUnSuccessValue] = useState(0);

    useEffect(() => {

        const fetchDataSet = async () => {
            try {
                const data = await getAllSet();
                const UnSuccess = data.find(item => item.typeField === 'Поездку отменил клиент менее чем за 2 часа')?.valueField || 0;
                setUnSuccessValue(UnSuccess)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchDataSet();
  }, []);

    const fetchOrders = async () => {
        let userData
        try {
            userData = await getOneByPhone(number)

            if (!userData.id) return


        } catch (error) {
            console.log(error.response.data.message);
        }

        try {

            getAllByUser(userData.id).then(data => {

                const allUserOrders = data.map(el => {
                    return { ...el, departureTime: moment(new Date(el.dateTimeStart)).format("YYYY-MM-DD HH:mm") }
                })

                setSearchResults(allUserOrders.filter(item => new Date(item.dateTimeStart) > new Date()));
            })
        } catch (error) {
            console.log(error.response.data.message);
        }

    }

    async function handleRemoveOrder(order) {
        const dateNow = new Date();
        const orderDate = new Date(order.dateTimeStart);
        let diff = (orderDate.getTime() - dateNow.getTime()) / 1000;
        diff /= (60 * 60); // Конвертация разницы в часы
    
        deleteOrder({ id: order.id }).then(async (res) => {
            if (res.message === 'Бронь удалена успешно') {
                openModal(res.message);
                setSearchResults(searchResults.filter(item => item.id !== order.id))
    
                if (diff < 2) {
                    // Вычисляем количество баллов для снятия
                    const pointsToDeduct = order.seats * UnSuccessValue; // 100 баллов за каждое место
                    const userId = order.user.id;
    
                    let changePointsRes = await changePoints({ userId: userId, action: 'decreaseCanceled', points: pointsToDeduct });
                    createCacheReserved({ userId: userId, dateTimeStart: null, dateTimeStartM: order.dateTimeStart });
                    if (changePointsRes.message !== 'Баллы пользователя были обновлены успешно') {
                        openModal('Ошибка при изменении баллов.');
                    }
                }
            } else {
                openModal('Ошибка при удалении брони.');
            }
        });
    }

    const openModal = (str) => {
        if (str) {
            setModalMessage(str)
        }
        setShowModal(true)


    }

    const closeModal = () => {
        setShowModal(false)
        setModalMessage('')
    }

    const handleEditOrder = async (order) => {
        try {
            const stops = await getAllStops(order.trip.routeId);
            setStopsToChange(stops);

            // Find the stop object that matches the order's start stop
            const startStop = stops.find((stop) => stop.name === order.startStop.name);

            setOrderToChange({
                ...order,
                startStop: startStop || stops[0] // Set the initial start stop to the first one if not found
            });

            openModal(false);
        } catch (error) {
            console.log(error);
        }
    };

    
    const saveOrderChange = async () => {
        try {
            const res = await updateOrder({
                id: orderToChange.id, // передаем id заказа для обновления
                seats: orderToChange.seats,
                comment: orderToChange.comment,
                startStop: orderToChange.startStop, // передаем объект startStop
                startStopId: orderToChange.startStop.id, // передаем id startStop
                tripId: orderToChange.trip.id, // передаем id поездки
                user: orderToChange.user,
                noPoint: true
            });
    
            if (res.result) {
                console.log('ok');
                closeModal();
                openModal('Бронь изменена успешно');
            } else {
                console.log('not ok');
                closeModal();
                openModal(`Бронь не изменена. Доступно мест: ${res.availableSeats}`);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleSelectChange = (e) => {
        console.log('Выбранная остановка:', e.target.value); // Вывод в консоль выбранной остановки на русском
    };

    return (
        <div className={styles.body}>
            <div className={styles.header}>
                <div className={styles.title}>Оператор</div>
                <div className={styles.btn}>
                    <Link to="/">
                        На главную
                    </Link>
                </div>
            </div>
            <div className={styles.search}>
                <p>Найти по номеру телефона</p>
                <input
                    type="text"
                    value={number}
                    onChange={(e) => { setNumber(e.target.value) }}
                    placeholder='Например, +375110000000'
                />
                <div className={styles.btn}
                    onClick={fetchOrders}
                >
                    Искать
                </div>


            </div>
            {searchResults.filter(trip => trip.status === 'Забронировано').length > 0 && <div className={styles.results}>

                <div className={styles.userInfo}>
                    <div>
                        Имя: {searchResults[0].user.name}
                    </div>
                    <div>
                        Телефон: {searchResults[0].user.phoneNumber}
                    </div>
                </div>
                {searchResults
                .map(el => {
                    
                    return <div className={styles.result} key={el.id}>
                        <p>Отправление: {el.departureTime} {el.startStop.name}</p>
                        <p>Рейс: {el.trip.routeId === 2 ? 'Поставы-Минск' : 'Минск-Поставы'}</p>
                        <p>Забронировано мест: {el.seats}</p>
                        {el.comment && <p>Примечание: {el.comment}</p>}
                        <div
                            className={styles.btn + ' ' + styles.edit}
                            onClick={() => { handleEditOrder(el) }}
                        >Изменить бронь</div>
                        <div
                            className={styles.btn + ' ' + styles.delete}
                            onClick={() => { handleRemoveOrder(el) }}
                        >Удалить бронь</div>
                    </div>
                })}
            </div>

            }

            {modalMessage ?
                <Modal visible={showModal} setVisible={closeModal}>
                    {modalMessage}

                </Modal>
                :
                <Modal visible={showModal} setVisible={closeModal}>
                    <div className={styles.modalRow}><label htmlFor="seats">Мест забронировано</label>
                    <input
                    
                    id='seats'
                        type="text"
                        placeholder='Количество мест'
                        value={orderToChange.seats}
                        onChange={(e) => { setOrderToChange(
                            {...orderToChange,
                                seats: e.target.value
                            }
                            )}}
                    />
                    </div>
                    <div className={styles.modalRow}>
                        <label htmlFor="stops">Остановка:</label>
                        <select
                            id="stops"
                            onChange={(e) => {
                                const selectedStop = stopsToChange.find(
                                    (item) => item.name === e.target.value
                                );
                                setOrderToChange({
                                    ...orderToChange,
                                    startStop: { id: selectedStop.id, name: selectedStop.name }
                                });
                                handleSelectChange(e);
                            }}
                            value={orderToChange.startStop.name}
                        >
                            {stopsToChange.map((option) => (
                                <option key={option.id} value={option.name}>
                                    {option.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className={styles.modalRow}>
                        <label htmlFor="comment">Примечание</label>
                     <textarea 
                     id='comment'
                    type="text" 
                    placeholder='Примечание' 
                    value={orderToChange.comment}
                    onChange={(e)=>{
                        
                        setOrderToChange(
                            {...orderToChange,
                                comment: e.target.value
                            }
                            )
                    }}
                    />   
                    </div>
                    
                        
                    

                    <div
                        className={styles.btn + ' ' + styles.edit}
                        onClick={() => { saveOrderChange() }}
                    >Сохранить изменения</div>

                </Modal>
            }

        </div>
    );
};

export default Dispetcher;