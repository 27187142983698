import { createSlice } from '@reduxjs/toolkit'

export const tripDetailsSlice = createSlice({
  name: 'tripDetails',
  initialState: {
    selectedRoute: '',
    date: `${new Date().getDate()}.${new Date().getMonth() + 1}.${new Date().getFullYear()}`,
    selectedStop: '',
    seat: 1,
    tripId: 0
  },
  reducers: {
    changeSelectedRoute: (state, action) => {
      state.selectedRoute = action.payload
    },
    changeDate: (state, action) => {
      state.date = action.payload
    },
    changeSelectedStop: (state, action) => {
      state.selectedStop = action.payload
    },
    changeSeat: (state, action) => {
      state.seat = action.payload
    },
    setTripId: (state, action) => {
      state.tripId = action.payload
    },
  },
})

export const { changeSelectedRoute, changeDate, changeSelectedStop, changeSeat, setTripId } = tripDetailsSlice.actions


export const selectSelectedRoute = (state) => state.tripDetails.selectedRoute
export const selectDate = (state) => state.tripDetails.date
export const selectSelectedStop = (state) => state.tripDetails.selectedStop
export const selectSeat = (state) => state.tripDetails.seat
export const selectTripId = (state) => state.tripDetails.tripId

export default tripDetailsSlice.reducer
