import { $authHost, $host } from "./index";

export const createCacheReserved = async (cache) => {
    const {data} = await $host.post('api/cacheReserved/createArrived', cache)
    return data
}

export const getThisMonth = async (userId) => {
    const {data} = await $host.get('api/cacheReserved/getThisMonth', userId)
    return data
}

export const getAll = async (userId) => {
    const {data} = await $host.get('api/cacheReserved/getAll', { params: { userId } })
    return data
}

/* export const getAllSuccessful = async () => {
    const {data} = await $host.get('api/cacheReserved/getAllSuccessful')
    return data
}

export const getSuccessfulThisMonth = async () => {
    const {data} = await $host.get('api/cacheReserved/getSuccessfulThisMonth')
    return data
} */