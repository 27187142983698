import React from 'react';
import '../../app.css';
import './privacyPolicy.css';
import { useNavigate } from 'react-router-dom';


function PrivacyPolicy() {
    const navigate = useNavigate() 

    function handleNavigate() {
        navigate('/')
    }

    return (
        <div className='page__privacyPolicy'>
            <div className='container'>
                <h2 className='page__privacyPolicy-header'>Политика конфиденциальности</h2>   

                <div className='page__privacyPolicy-btnWrap'>
                    <div className='page__privacyPolicy-btn' onClick={handleNavigate}>На главную</div>
                </div>                     
            </div>
        </div>
    )
}

export default PrivacyPolicy;