import { createSlice } from '@reduxjs/toolkit'

export const driverDetailsSlice = createSlice({
  name: 'driverDetails',
  initialState: {
    seatsRemain: 15,
  },
  reducers: {
    increaseSeatsRemain: (state) => {
        state.seatsRemain += 1
    },
    decreaseSeatsRemain: (state) => {
        state.seatsRemain -= 1
    },
    setSeatsRemain: (state, action) => {
      state.seatsRemain = action.payload
  },
  },
})

export const { increaseSeatsRemain, decreaseSeatsRemain, setSeatsRemain } = driverDetailsSlice.actions


export const selectSeatsRemain = (state) => state.driverDetails.seatsRemain

export default driverDetailsSlice.reducer
