import axios from "axios"

const $host = axios.create({
    /* baseURL: 'http://localhost:5000/', */
    baseURL: 'https://api.606tk.by',
})

const $authHost = axios.create({
    /* baseURL: 'http://localhost:5000/', */
    baseURL: 'https://api.606tk.by',

})

const authInterceptor = config => {
    config.headers.authorization = `Bearer ${localStorage.getItem('token')}`
    return config
}

$authHost.interceptors.request.use(authInterceptor)

export {
    $host,
    $authHost
}