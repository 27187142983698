import React, { useState } from 'react';
import './calendar.css';
import { useDispatch, useSelector } from 'react-redux';
import { changeDate, selectDate, changeSelectedStop } from '../../features/tripDetails/tripDetailsSlice';

function CalendarForAdmin() {
  const currentDate = new Date();
  const [currentMonth, setCurrentMonth] = useState(currentDate.getMonth());
  const [currentYear, setCurrentYear] = useState(currentDate.getFullYear());
  const selectedDate = useSelector(selectDate);
  
  const dispatch = useDispatch();

  const months = [
    'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
    'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь',
  ];

  const daysOfWeek = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

  const prevMonth = (event) => {
    event.stopPropagation();
    setCurrentMonth((prevMonth) => {
      if (prevMonth === 0) {
        setCurrentYear((prevYear) => prevYear - 1);
        return 11;
      } else {
        return prevMonth - 1;
      }
    });
  };

  const nextMonth = (event) => {
    event.stopPropagation();
    setCurrentMonth((prevMonth) => {
      if (prevMonth === 11) {
        setCurrentYear((prevYear) => prevYear + 1);
        return 0;
      } else {
        return prevMonth + 1;
      }
    });
  };

  const getDaysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const renderCalendar = () => {
    const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay() || 7;
    const daysInMonth = getDaysInMonth(currentMonth, currentYear);
    const prevMonthDays = getDaysInMonth(currentMonth - 1, currentYear);

    let daysToDisplay = [];

    // Добавляем дни предыдущего месяца в начало
    for (let i = 0; i < firstDayOfMonth - 1; i++) {
      daysToDisplay.unshift({
        value: prevMonthDays - i,
        isCurrentMonth: false,
        month: currentMonth - 1,
        year: currentYear
      });
    }

    // Добавляем дни текущего месяца
    for (let i = 1; i <= daysInMonth; i++) {
      daysToDisplay.push({
        value: i,
        isCurrentMonth: true,
        month: currentMonth,
        year: currentYear
      });
    }

    // Добавляем дни следующего месяца в конец
    const lastDayOfMonth = new Date(currentYear, currentMonth, daysInMonth).getDay() || 7;
    const remainingDays = 7 - lastDayOfMonth;

    for (let i = 1; i <= remainingDays; i++) {
      daysToDisplay.push({
        value: i,
        isCurrentMonth: false,
        month: currentMonth + 1,
        year: currentYear
      });
    }

    const daysToDisplayMergedSplit = [];

    for (let i = 0; i < daysToDisplay.length; i += 7) {
      daysToDisplayMergedSplit.push(daysToDisplay.slice(i, i + 7));
    }

    function handleDayClick(value, month, year) {
      dispatch(changeSelectedStop(''));
      dispatch(changeDate(`${value}.${month + 1}.${year}`));
    }

    return (
      <div className='calendar-days'>
        {daysToDisplayMergedSplit.map((row, index) => (
          <div key={`row-${index}`} className='calendar-row'>
            {row.map((day) => {
              const dayId = `${day.value}-${day.month}-${day.year}`;
              const selectedDay = selectedDate.split('.');
              const selectedDayId = `${selectedDay[0]}-${parseInt(selectedDay[1], 10) - 1}-${selectedDay[2]}`;

              const isToday = currentMonth === day.month && day.isCurrentMonth && day.value === currentDate.getDate();
              const isDisabled = !day.isCurrentMonth && (currentYear > day.year || (currentYear === day.year && currentMonth > day.month) || (currentYear === day.year && currentMonth === day.month && day.value < currentDate.getDate()));

              return (
                <div
                  key={dayId}
                  className={`calendar-day ${selectedDayId === dayId ? 'calendar-day-selected' : ''} ${isDisabled ? 'calendar-day-disabled' : ''} ${isToday ? 'calendar-day-today' : ''}`}
                  onClick={isDisabled ? () => {} : () => { handleDayClick(day.value, day.month, day.year) }}
                >
                  {day.value}
                </div>
              );
            })}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="calendar">
      <div className='calendar-header'>
        <div className='calendar-header-title'>
          <div className='calendar-arrow' onClick={prevMonth}>&#8592;</div>
          <h1 className='calendar-title'>{months[currentMonth]} {currentYear}</h1>
          <div className='calendar-arrow' onClick={nextMonth}>&#8594;</div>
        </div>
        <div className="days-of-week">
          {daysOfWeek.map((day) => (
            <div key={day} className="day-of-week">{day}</div>
          ))}
        </div>
      </div>
      <div>
        {renderCalendar()}
      </div>
    </div>
  );
}

export default CalendarForAdmin;
