import { useNavigate } from 'react-router-dom';
import './error.css';


function Error() {
    const navigate = useNavigate()    

    function handleNavigate() {
        navigate('/')
    }

    return (
        <div className="page__error">
            <div className='page__error-message-wrap'>
                <p className='page__error-title'>404</p>
                <p className='page__error-message'>страница не найдена</p>
                <div className='page__error-btn' onClick={handleNavigate}>На главную</div>
            </div>
        </div>
    )
}

export default Error;