import React, { useState, useEffect } from 'react';
import { getAllSuccessful, getSuccessfulThisMonth } from '../../../http/ordersApi';
/* import { getAllSuccessful, getSuccessfulThisMonth } from '../../../http/cacheReservedApi'; */
import './modal.css';

function StatisticsModal(props) {
    const { handleCloseAll } = props;

    const [allSuccessfulCount, setAllSuccessfulCount] = useState(0);
    const [successfulThisMonthCount, setSuccessfulThisMonthCount] = useState(0);

    useEffect(() => {
        getAllSuccessful().then(data => {
            setAllSuccessfulCount(data.totalSeats); 
        }).catch(err => {
            console.error('Error fetching total successful seats:', err);
        });

        getSuccessfulThisMonth().then(data => {
            setSuccessfulThisMonthCount(data.monthlySeats);
        }).catch(err => {
            console.error('Error fetching successful seats for this month:', err);
        });
    }, []); 

    return (
        <div className='admin__modal-wrapper'>
            <div className='admin__modal-container'>
                <div className='admin__modal-close' onClick={handleCloseAll}></div>
                <h3 className='admin___modal-title'>Статистика</h3>
                <div className='admin__modal-content'>
                    <div className='statistic_one'>
                        <h3>Успешных поездок за прошлый месяц:</h3>
                        <h4>{allSuccessfulCount}</h4>
                    </div>
                    <div className='statistic_one'>
                        <h3>Успешных поездок за текущий месяц:</h3>
                        <h4>{successfulThisMonthCount}</h4>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StatisticsModal;



