import React, {useState, useEffect, useRef} from 'react';
import { useSelector } from 'react-redux';
import { 
    selectSelectedStop
} from '../../features/tripDetails/tripDetailsSlice';
import PlaceForm from './PlaceForm';


function LandingSite() {
    const stop = useSelector(selectSelectedStop);
    const stopName = stop.name
    const [isToShow, setIsToShow] = useState(false);
    const containerRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (containerRef.current && !containerRef.current.contains(event.target)) {
            setIsToShow(false);
          }
        };
    
        document.addEventListener('click', handleClickOutside);
    
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
      }, []);

    return (
        <>
            <div ref={containerRef} onClick={() => {setIsToShow(!isToShow)}} className='additional__container'>
                <div className='additional_title'>Выберите место посадки</div>
                <div className='additional-value'>{stopName ? stopName : 'Откуда вас забрать?'}</div>            
            </div> 
            {isToShow && <PlaceForm closeForm={() => {setIsToShow(false)}}/>}
        </>
    );
}

export default LandingSite;
