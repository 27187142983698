import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { 
    selectSelectedRoute,
    changeSelectedRoute,  
    changeSelectedStop,
} from '../../features/tripDetails/tripDetailsSlice';
import {    
    selectRoutes,    
} from '../../features/routes/routesSlice';
import {changeStops} from '../../features/stops/stopsSlice'
import { getAllStops } from '../../http/stopsApi';


function Route() {            
    const selectedRoute = useSelector(selectSelectedRoute);
    const dispatch = useDispatch();
    const routes = useSelector(selectRoutes);

    function handleClickRoute(route) {
        dispatch(changeSelectedStop(''))
        dispatch(changeSelectedRoute(route));
        getAllStops(route.id).then(data => {dispatch(changeStops(data))})  
    }

    return (
        <>        
            <div className='direction-text'>Выберите направление</div>
            <div className='direction-btn__wrap'>
                {
                    routes[0] ? 
                    <>
                        <div onClick={() => handleClickRoute(routes[1])} className={`direction-btn direction-btn-left ${selectedRoute.name === routes[1].name ? 'direction-btn-selected' : ''}`}>{routes[0].name} → {routes[1].name}</div>
                        <div onClick={() => handleClickRoute(routes[0])} className={`direction-btn direction-btn-right ${selectedRoute.name === routes[0].name ? 'direction-btn-selected' : ''}`}>{routes[1].name} → {routes[0].name}</div>
                    </>
                    :
                    <p>...</p>
                }
                
            </div>     
        </>           
    );
}

export default Route;
