import './trips.css'
import './modal.css'
import moment from 'moment';
import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createTrip, deleteTrip, getByBus } from '../../../http/tripsApi';
import { getAllBuses } from '../../../http/busesApi';
import { getAllRoutes } from '../../../http/routesApi'; 
import { getAllByTrip } from '../../../http/ordersApi';

function TripsModal(props) {
    const dispatch = useDispatch()
    const currentDate = new Date();
    const [trips, setTrips] = useState('');
    const [buses, setBuses] = useState('');

    const [busId, setBusId] = useState('');
    const [year, setYear] = useState(currentDate.getFullYear());
    const [month, setmonth] = useState(currentDate.getMonth() + 1);
    const [day, setDay] = useState(currentDate.getDate());
    const [hours, setHours] = useState('');
    const [minutes, setMinutes] = useState('');
    const [createBusId, setCreateBusId] = useState('');
    const [createRouteId, setCreateRouteId] = useState('');
    const [routes, setRoutes] = useState('');
    const [showOrdersFor, setShowOrdersFor] = useState('');
    const [usersForTrip, setUsersForTrip] = useState('');
    const {handleCloseAll} = props;

    useEffect(() => {
        getAllBuses().then(data => {setBuses(data)});
        getAllRoutes().then(data => {setRoutes(data)})
    }, [])

    function handleBusChange(busId) {
        setBusId(busId);
        getByBus(busId).then(data => {setTrips(data)})
    }   

    async function handleTripDelete(trip) {        
        try {
            const users = await getAllByTrip(trip.id);
            const hasBookings = users.some(user => user.status === 'Забронировано');

            if (hasBookings) {
                alert('Нельзя удалить рейс, так как на него есть активная бронь');
                return;
            }

            const dateTrip = moment(trip.dateTimeStart).format("DD.MM.YYYY HH:mm");
            const tripLocalStorage = 'trip-1-'+dateTrip
            localStorage.removeItem(tripLocalStorage);

            await deleteTrip({id: trip.id});
            const updatedTrips = await getByBus(busId);
            setTrips(updatedTrips);
            alert(`Остановка удалена успешно!`);
        } catch (error) {
            console.log(error);
        }
    }

    function handleTripCreate() {
        const dateTimeStart = `${year}-${month}-${day} ${hours}:${minutes}:00`;
        let dateTimeNext;
        if (createRouteId === 2) {
            dateTimeNext = moment(dateTimeStart).add(3, 'hours').add(10, 'minutes').format("YYYY-MM-DD HH:mm:ss");
        } else if (createRouteId === 1) {
            dateTimeNext = moment(dateTimeStart).add(2, 'hours').add(35, 'minutes').format("YYYY-MM-DD HH:mm:ss");
        }        
        
        createTrip({
            dateTimeStart: dateTimeStart,
            dateTimeFinish: dateTimeNext,
            maxSeats: 15,
            busId: createBusId,
            routeId: createRouteId,
        }).then(data => {
            busId && getByBus(busId).then(data => {setTrips(data)})
        })
    }

    async function showOrders(trip) {
        if (showOrdersFor === trip.id) {
            setShowOrdersFor('');
        } else {
            setShowOrdersFor(trip.id);
        }

        const data = await getAllByTrip(trip.id);
        setUsersForTrip(data);
    }

    return (       
        <div className='admin__modal-wrapper'>            
            <div className='admin__modal-container'>
                <div className='admin__modal-close' onClick={() => handleCloseAll()}></div>
                {
                    buses && buses.map(bus => {
                        return <div key={bus.id} className='admin__modal-checkbox'>
                        <p className='admin__modal-checkbox_text'>{bus.name}</p>
                        <input
                            type="checkbox"
                            checked={bus.id === busId}
                            onChange={() => {handleBusChange(bus.id)}}
                        />
                    </div> 
                    })                    
                }
                <div className='admin__modal_trip-content'>
                    {                    
                        trips && trips.map(trip => {
                            const formattedDate = moment(trip.dateTimeStart).format("YYYY-MM-DD HH:mm:ss");
                            
                            return <div key={trip.id} className='admin__modal_trip-row'>
                                <div className='admin__modal_trip-info'>
                                    <p className='admin__modal_trip-text' onClick={() => showOrders(trip)}>{formattedDate} - {trip.routeId === 2 ? `${routes[0].name} → ${routes[1].name}` : `${routes[1].name} → ${routes[0].name}`}</p> 
                                    {showOrdersFor === trip.id && Object.keys(usersForTrip).length >= 1 &&
                                        <>
                                            <div className='admin__modal_trip-user'>Всего человек: {Object.keys(usersForTrip).length}</div>
                                            {usersForTrip
                                            .filter(trip => trip.status === 'Забронировано')
                                            .map((order) => {
                                                return <div key={order.id} className='admin__modal_trip-user'>
                                                    <p>Имя: {order.user.name}</p>
                                                    <p>Телефон: {order.user.phoneNumber}</p>
                                                    <p>Мест: {order.seats}</p>
                                                </div>
                                            })}
                                        </>
                                    }
                                    {showOrdersFor === trip.id && Object.keys(usersForTrip).length === 0 &&
                                        <p>На этот рейс нет брони</p>
                                    }
                                </div>                                
                                <div className='admin__modal-btn admin__modal-danger' onClick={() => handleTripDelete(trip)}>&#10006;</div>                           
                            </div>
                        })                    
                    }
                </div>

                <div className='admin__modal_trip-create'>
                    <div className='admin__modal_trip-create_form mb15'>
                        <input
                            type="text"
                            value={year}
                            onChange={e => {setYear(e.target.value)}}
                            placeholder="год"
                            className='admin_modal-input admin__trip_modal-input'
                        />
                        <input
                            type="text"
                            value={month}
                            onChange={e => {setmonth(e.target.value)}}
                            placeholder="месяц"
                            className='admin_modal-input  admin__trip_modal-input'
                        />
                        <input
                            type="text"
                            value={day}
                            onChange={e => {setDay(e.target.value)}}
                            placeholder="день"
                            className='admin_modal-input  admin__trip_modal-input'
                        />
                        <input
                            type="text"
                            value={hours}
                            onChange={e => {setHours(e.target.value)}}
                            placeholder="часы"
                            className='admin_modal-input  admin__trip_modal-input'
                        />
                        <input
                            type="text"
                            value={minutes}
                            onChange={e => {setMinutes(e.target.value)}}
                            placeholder="минуты"
                            className='admin_modal-input admin__trip_modal-input'
                        />
                        
                    </div>
                    <div className='mb15'>
                        {                        
                            routes && routes.map(route => {
                                return <div key={route.id} className='admin__modal-checkbox'>
                                <p className='admin__modal-checkbox_text'>{route.id === 2 ? `${routes[0].name} → ${routes[1].name}` : `${routes[1].name} → ${routes[0].name}`}</p>
                                <input
                                    type="checkbox"
                                    checked={route.id === createRouteId}
                                    onChange={() => {setCreateRouteId(route.id)}}
                                />
                            </div> 
                            })  
                        }
                    </div>
                    <div className='mb15'>
                    {
                        buses && buses.map(bus => {
                            return <div key={bus.id} className='admin__modal-checkbox'>
                            <p className='admin__modal-checkbox_text'>{bus.name}</p>
                            <input
                                type="checkbox"
                                checked={bus.id === createBusId}
                                onChange={() => {setCreateBusId(bus.id)}}
                            />
                        </div> 
                        })                    
                    }
                    </div>
                    <div className='admin__modal-btn admin__modal-success' onClick={() => handleTripCreate()}>&#10003;</div>                           
                </div>
            </div> 
        </div>                    
    )
}

export default TripsModal;