import React from 'react';
import './reservation.css'
import Route from './Route';
import DateCard from './DateCard';
import LandingSite from './LandingSiteCard';
import SeatsCard from './SeatsCard';
import { useSelector } from 'react-redux';
import { 
    selectSelectedRoute,
    selectSelectedStop,
} from '../../features/tripDetails/tripDetailsSlice';
// import Calendar from '../Calendar/Calendar';

function Reservation() { 
    const route = useSelector(selectSelectedRoute);
    const place = useSelector(selectSelectedStop);
    
    return (
        <div className="container">
            <div className='reservation__content'>
                <Route />
                <div className='additional__wrap'>
                    <DateCard />                    
                    {route && <LandingSite />}
                    {place && <SeatsCard />}
                </div>            
            </div>
        </div>
    );
}

export default Reservation;
