import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { 
    selectSelectedRoute,
    changeSelectedStop,
} from '../../features/tripDetails/tripDetailsSlice';
import {selectStops} from '../../features/stops/stopsSlice'


function PlaceForm(props) {
    const dispatch = useDispatch();
    const route = useSelector(selectSelectedRoute);
    let stops = useSelector(selectStops)
    stops = stops.filter((stop) => stop.routeId === route.id);
    stops = stops.sort((a, b) => a.timeOffSet - b.timeOffSet);

    const {closeForm} = props;

    const handleClick = (stop) => {
        dispatch(changeSelectedStop(stop))
    }    

    return (
        <>
            <div className='places__form'>
                <div className='places__form-question'>Где вас забрать?</div>   
                <div className='places__form-content'>
                    {
                        stops.map((stop) => {
                            return <div key={stop.id} onClick={() => handleClick(stop)} className="places__form-paragraph">{stop.name}</div>
                        })
                    }
                </div>
            </div> 
        </>
    );
}

export default PlaceForm;
