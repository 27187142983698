import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { 
    selectSeat,
    changeSeat,  
} from '../../features/tripDetails/tripDetailsSlice';


function SeatsCard() {
    const selectedSeat = useSelector(selectSeat);
    const dispatch = useDispatch();

    return (
        <div className='additional-seats'>
            <div className='additional_title additional_title-seats'>Укажите количество мест</div>
            <div className='seats'>
                {[1, 2, 3].map((seatNumber) => (
                    <div
                        key={seatNumber}
                        onClick={() => dispatch(changeSeat(seatNumber))}
                        className={`seat ${selectedSeat === seatNumber ? 'seat-selected' : ''}`}
                    >
                        {seatNumber}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default SeatsCard;
