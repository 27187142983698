import './modal.css'
import './employees.css'
import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createUser, deleteUser, getAllUsers } from '../../../http/userApi';


function EmployeesModal(props) {
    const dispatch = useDispatch()
    const [isSortByAdmins, setIsSortByAdmins] = useState(false);
    const [isSortByDrivers, setIsSortByDrivers] = useState(false);
    const [isSortByDispetchers, setIsSortByDispetchers] = useState(false);
    const [users, setUsers] = useState('');
    const [newUserName, setNewUserName] = useState('');
    const [newUserPhoneNumber, setNewUserPhoneNumber] = useState('');
    const [newUserPassword, setNewUserPassword] = useState('');
    const [newUserRole, setNewUserRole] = useState('');
    const {handleCloseAll} = props;

    const handleSortByAdmins = () => {
        try {
            const role = "ADMIN"
            getAllUsers(role).then(data => {setUsers(data)});
            setIsSortByAdmins(true);
            setIsSortByDrivers(false);
            setIsSortByDispetchers(false);
        } catch (error) {
            console.log(error)
        }        
    };
    const handleSortByDrivers = () => { 
        try {
            const role = "DRIVER" 
            getAllUsers(role).then(data => {setUsers(data)});
            setIsSortByAdmins(false);
            setIsSortByDrivers(true);
            setIsSortByDispetchers(false);
        } catch (error) {
            console.log(error)
        }        
    };
    const handleSortByDispetchers = () => {
        try {
            const role = "DISPETCHER"
            getAllUsers(role).then(data => {setUsers(data)});
            setIsSortByAdmins(false);
            setIsSortByDrivers(false);
            setIsSortByDispetchers(true);
        } catch (error) {
            console.log(error)
        }        
    };

    function handleUserDelete(user) {
        let role;
        if (isSortByAdmins) {
            role = "ADMIN"
        } else if (isSortByDrivers) {
            role = "DRIVER"
        } else if (isSortByDispetchers) {
            role = "DISPETCHER"
        }
        try {
            deleteUser({id: user.id}).then(data => {                
                getAllUsers(role).then(data => {setUsers(data)});
                alert(`Сотрудник ${user.name} удален успешно!`);
            })            
        } catch (error) {
            console.log(error)
        }
    }

    function handleUserCreate() {
        try {
            createUser({name: newUserName, phoneNumber: newUserPhoneNumber, password: newUserPassword, role: newUserRole}).then(data => {
                setNewUserName('');
                setNewUserPhoneNumber('');
                setNewUserPassword('');
                setNewUserRole('');
                alert('Сотрудник добавлен успешно!');
            }).catch(error => {alert(error.response.data.message)})
        } catch (error) {
            console.log(error)
        }
    }


    return (
        <div className='admin__modal-wrapper'> 
            
            <div className='admin__modal-container'>
                <div className='admin__modal-close' onClick={() => handleCloseAll()}></div>
                <div className='admin__modal-checkbox'>
                    <p className='admin__modal-checkbox_text'>Администраторы</p>
                    <input
                        type="checkbox"
                        checked={isSortByAdmins}
                        onChange={handleSortByAdmins}
                    />
                </div> 
                <div className='admin__modal-checkbox'>
                    <p className='admin__modal-checkbox_text'>Водители</p>
                    <input
                        type="checkbox"
                        checked={isSortByDrivers}
                        onChange={handleSortByDrivers}
                    />
                </div>  
                <div className='admin__modal-checkbox'>
                    <p className='admin__modal-checkbox_text'>Операторы</p>
                    <input
                        type="checkbox"
                        checked={isSortByDispetchers}
                        onChange={handleSortByDispetchers}
                    />
                </div>

                <div className='admin__modal_employees-container'>
                    {                    
                        users && users.map((user) => {
                            return <div key={user.id} className='admin__modal_employees-row'>
                                {user.name} - {user.phoneNumber}
                                <div className='admin__modal-btn admin__modal-danger' onClick={() => handleUserDelete(user)}>&#10006;</div>
                            </div>
                        })
                    }
                </div>

                <h3 className='admin___modal-title'>Создать сотрудника</h3>
                <div className='admin__modal_employees-create'>
                    <input
                        type="text"
                        value={newUserName}
                        onChange={e => setNewUserName(e.target.value)}
                        placeholder="имя"
                        className='admin__modal-employee_input'
                    />
                    <input
                        type="text"
                        value={newUserPhoneNumber}
                        onChange={e => setNewUserPhoneNumber(e.target.value)}
                        placeholder="номер телефона"
                        className='admin__modal-employee_input'
                    />
                    <input
                        type="text"
                        value={newUserPassword}
                        onChange={e => setNewUserPassword(e.target.value)}
                        placeholder="пароль"
                        className='admin__modal-employee_input'
                    />
                    <div className='admin__modal-employee_checkbox-wrap'>
                        <div className='admin__modal-checkbox'>
                            <p className='admin__modal-checkbox_text'>Администратор</p>
                            <input
                                type="checkbox"
                                checked={newUserRole === "ADMIN"}
                                onChange={() => {setNewUserRole("ADMIN")}}
                            />
                        </div> 
                        <div className='admin__modal-checkbox'>
                            <p className='admin__modal-checkbox_text'>Водитель</p>
                            <input
                                type="checkbox"
                                checked={newUserRole === "DRIVER"}
                                onChange={() => {setNewUserRole("DRIVER")}}
                            />
                        </div> 
                        <div className='admin__modal-checkbox'>
                            <p className='admin__modal-checkbox_text'>Оператор</p>
                            <input
                                type="checkbox"
                                checked={newUserRole === "DISPETCHER"}
                                onChange={() => {setNewUserRole("DISPETCHER")}}
                            />
                        </div> 
                    </div>
                    <div className='admin__modal-btn admin__modal-success' onClick={() => handleUserCreate()}>&#10003;</div>
                </div>
            </div>
        </div>
    )
}

export default EmployeesModal;