import React from 'react';
import classes from './Toggle.module.css'

const Toggle = ({checked, setChecked}) => {



    return (
        
        <label className={classes.checkboxToggle}>
            <input 
            type="checkbox"
            checked={checked? true: false}
            onChange={()=>{
                setChecked(!checked)
            }}
            />
            <span className={classes.checkboxToggleSwitch}></span>
        </label>
    );
};

export default Toggle;