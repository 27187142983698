import './usersModal.css';
import React, { useEffect, useState } from 'react';
import { deleteOrder, getAllByDay } from '../../../http/ordersApi';
import { updateSeatsWithList, getTripById } from '../../../http/tripsApi';
import { changePoints } from '../../../http/userApi';
import { createCacheReserved } from '../../../http/cacheReservedApi';
import { getAllSet } from '../../../http/settingsApi';
import Modal from '../../../components/UI/Modal/Modal';
import { useSelector } from 'react-redux';
import { selectDate } from '../../../features/tripDetails/tripDetailsSlice';
import CalendarForAdmin from '../../../components/Calendar/CalendarForAdmin';
import moment from 'moment';

const OrdersModal = ({ handleCloseAll }) => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const currentDay = new Date().getDate();

    const currentDate = useSelector(selectDate);
    const [isToShow, setIsToShow] = useState(false);
    const [allOrders, setAllOrders] = useState([]);
    const [sortedOrders, setSortedOrders] = useState([]);
    const [startDate, setStartDate] = useState(new Date(currentYear, currentMonth, currentDay));
    const [endDate, setEndDate] = useState(new Date(currentYear, currentMonth + 1, currentDay - 1));
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [calendarMode, setCalendarMode] = useState(0);
    const [openTrips, setOpenTrips] = useState({});
    const [searchInput, setSearchInput] = useState('');
    const [UnSuccessValue, setUnSuccessValue] = useState(0);
    const [passengerCounts, setPassengerCounts] = useState([]);
    const [withListSeats, setWithListSeats] = useState({}); 
    const [loadedTripIds, setLoadedTripIds] = useState(new Set()); // Новое состояние для отслеживания загруженных tripId

    useEffect(() => {
        fetchOrders();
    }, [startDate, endDate]);

    useEffect(() => {
        if (calendarMode === 0) return;

        const newDate = new Date(
            currentDate.slice(-4),
            currentDate.slice(currentDate.indexOf('.') + 1, currentDate.indexOf('.', 3)) - 1,
            currentDate.slice(0, currentDate.indexOf('.'))
        );

        if (calendarMode === 1) {
            setStartDate(newDate);
        } else if (calendarMode === 2) {
            setEndDate(newDate);
        }
    }, [currentDate]);

    useEffect(() => {
        const fetchDataSet = async () => {
            try {
                const data = await getAllSet();
                const UnSuccess = data.find(item => item.typeField === 'Поездку отменил клиент менее чем за 2 часа')?.valueField || 0;
                setUnSuccessValue(UnSuccess);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchDataSet();
    }, []);

    function fetchOrders() {
        getAllByDay(startDate, endDate)
            .then(data => {
                const filteredData = data
                    .filter(order => {
                        const orderDate = moment(order.dateTimeStart).format('DD.MM.YYYY');
                        const selectedDate = moment(startDate).format('DD.MM.YYYY');
                        return orderDate === selectedDate;
                    })
                    .filter(trip => trip.status === 'Забронировано');
                setAllOrders(filteredData);
                setSortedOrders(filteredData);
            });
    }

    function handleSearchByPhone() {
        setSortedOrders(allOrders.filter(order => order.user.phoneNumber.includes(searchInput)));
    }

    function handleRefreshOrders() {
        setSortedOrders([...allOrders]);
    }

    const openModal = (str) => {
        setModalMessage(str);
        setShowModal(true);
    }

    const closeModal = (boolean) => {
        setShowModal(boolean);
        setModalMessage('');
    }

    const openCalendar = (option) => {
        if (option === 1) {
            setCalendarMode(1);
        } else if (option === 2) {
            setCalendarMode(2);
        }
        setIsToShow(true);
    }

    const closeCalendar = () => {
        setIsToShow(false);
        setCalendarMode(0);
    }

    const toggleTrip = (tripId) => {
        setOpenTrips((prevOpenTrips) => ({
            ...prevOpenTrips,
            [tripId]: !prevOpenTrips[tripId],
        }));
    };

    async function handleRemoveOrder(order) {
        const dateNow = new Date();
        const orderDate = new Date(order.dateTimeStart);
        let diff = (orderDate.getTime() - dateNow.getTime()) / 1000;
        diff /= (60 * 60); // Конвертация разницы в часы

        deleteOrder({ id: order.id }).then(async (res) => {
            if (res.message === 'Бронь удалена успешно') {
                openModal(res.message);
                setAllOrders(allOrders.filter(item => item.id !== order.id));
                setSortedOrders(allOrders.filter(item => item.id !== order.id));

                if (diff < 2) {
                    const pointsToDeduct = order.seats * UnSuccessValue; // 100 баллов за каждое место
                    const userId = order.user.id;

                    let changePointsRes = await changePoints({ userId: userId, action: 'decreaseCanceled', points: pointsToDeduct });
                    createCacheReserved({ userId: userId, dateTimeStart: null, dateTimeStartM: order.dateTimeStart });
                    if (changePointsRes.message !== 'Баллы пользователя были обновлены успешно') {
                        openModal('Ошибка при изменении баллов.');
                    }
                }
            } else {
                openModal('Ошибка при удалении брони.');
            }
        });
    }

    const trips = sortedOrders.reduce((acc, order) => {
        const tripKey = `trip-${order.trip.routeId}-${moment(order.trip.dateTimeStart).format('DD.MM.YYYY HH:mm')}`; 
        if (!acc[tripKey]) {
            acc[tripKey] = {
                tripId: order.trip.routeId,
                dateTime: order.dateTimeStart,
                time: moment(order.trip.dateTimeStart).format('HH:mm'),
                orders: [],
            };
        }
        acc[tripKey].orders.push(order);
        return acc;
    }, {});

    const handlePassengerCountChange = async (tripId, value) => {
        const withListSeatsValue = Number(value) || 0; 
        setPassengerCounts(prevCounts => {
            const newCounts = [...prevCounts];
            newCounts[tripId] = withListSeatsValue; 
            return newCounts;
        });
        try {
            await updateSeatsWithList(tripId, withListSeatsValue); 
        } catch (error) {
            openModal('Ошибка при обновлении мест.');
            console.error('Update seats error:', error);
        }
    };

    useEffect(() => {
        const fetchAllTripsData = async () => {
            const tripIds = Object.keys(trips).map(key => trips[key].orders[0].tripId);
            const uniqueTripIds = [...new Set(tripIds)]; // Get unique tripIds
            const newWithListSeats = {};
    
            for (const tripId of uniqueTripIds) {
                // Проверка, был ли tripId загружен ранее
                if (!loadedTripIds.has(tripId)) {
                    try {
                        const tripData = await getTripById(tripId);
                        newWithListSeats[tripId] = tripData.withListSeats; 
                        loadedTripIds.add(tripId); // Добавляем tripId в загруженные
                        
                        // Если withListSeats заполнено, обновляем passengerCounts
                        if (tripData.withListSeats) {
                            setPassengerCounts(prevCounts => {
                                const newCounts = [...prevCounts];
                                newCounts[tripId] = tripData.withListSeats; // Подставляем значение в passengerCounts
                                return newCounts;
                            });
                        }
                    } catch (error) {
                        console.error('Ошибка при получении рейса:', error);
                    }
                }
            }
    
            setWithListSeats(newWithListSeats); 
        };
    
        fetchAllTripsData();
    }, [trips, loadedTripIds]); // Теперь добавляем loadedTripIds в зависимости
     // Теперь добавляем loadedTripIds в зависимости

    return (
        <div className='admin__modal-wrapper'>
            <div className='admin__modal-container orders__modal'>
                <div className='admin__modal-close' onClick={() => handleCloseAll()}></div>
                <div className='usersModal__search-wrap usersModal__search-wrap-period'>
                    <p>Данные за период:</p>
                    <p className="usersModal__search-period">
                        Выберите дату:
                        <span
                            className='usersModal__search-date'
                            onClick={() => { openCalendar(1) }}
                        >
                            {moment(startDate).format('DD.MM.YYYY')}
                        </span>
                    </p>
                    {isToShow && <Modal visible={isToShow} setVisible={closeCalendar}><CalendarForAdmin limitation={false} /></Modal>}
                </div>

                <div className='usersModal__search-wrap'>
                    <input
                        className='usersModal__search-input'
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        placeholder='+375110000000'
                    />
                    <div className='usersModal__search-btns'>
                        <div className='admin__modal-btn admin__modal-success usersModal__search-btn' onClick={handleSearchByPhone}>Найти</div>
                        <div className='admin__modal-btn admin__modal-success usersModal__search-btn' onClick={handleRefreshOrders}>Сбросить</div>
                    </div>
                </div>
                <div className='users__modal-elem-wrap-all'>
                    {Object.keys(trips).map(tripKey => {
                        const trip = trips[tripKey];
                        const totalPassengers = trip.orders.reduce((total, order) => total + order.seats, 0);
                        const tripId = trip.orders[0].tripId; 
                        console.log(tripId)
                        return (
                            <div key={tripKey} className={`users__modal-elem ${openTrips[tripKey] ? 'open' : ''}`}>
                                <div onClick={() => toggleTrip(tripKey)} className='trip__header'>
                                    <p className='trip__info'>
                                        Рейс: {trip.tripId === 2 ? 'Поставы-Минск' : 'Минск-Поставы'}, Дата: {moment(trip.dateTime).format('DD.MM.YYYY')}, Время: {trip.time}
                                    </p>
                                    <p className='trip__count'>
                                       <span className='trip__count_with_list'>
                                            Количество пассажиров с листа:
                                            <input
    type="text"
    placeholder='Количество'
    value={passengerCounts[tripId] !== undefined ? passengerCounts[tripId] : (withListSeats[tripId] || 0)}
    onChange={(e) => {
        const value = Number(e.target.value) || 0; 
        handlePassengerCountChange(tripId, value); 
    }} 
/>

                                        </span>
                                        
                                        Количество пассажиров с сайта: {totalPassengers}
                                    </p>
                                </div>
                                <div className='trip__orders'>
                                    {trip.orders.map(order => (
                                        <div key={order.id} className='users__modal-elem'>
                                            <div className='users__modal-elem-second-wrap'>
                                                
                                                <p className='users__modal-elem-item'>Имя: {order.user.name}</p>
                                                <p className='users__modal-elem-item'>Номер телефона: {order.user.phoneNumber}</p>
                                                <p className='users__modal-elem-item'>Остановка: {order.startStop.name}</p>
                                                <p className='users__modal-elem-item'>Время: {moment(order.dateTimeStart).format("HH:mm")}</p>
                                                {(order.user.payedFor === order.tripId) && <p className='users__modal-elem-item'>Бронирование баллами</p>}
                                                <div className='admin__modal-btn admin__modal-danger' onClick={() => handleRemoveOrder(order)}>Удалить бронь</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <Modal visible={showModal} setVisible={closeModal}>{modalMessage}</Modal>
        </div>
    );
};

export default OrdersModal;
