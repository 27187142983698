import React, { useState, useRef, useEffect } from 'react';
import './contact.css';
import emailjs from '@emailjs/browser';
import { getData } from '../../http/contactApi';

function Contact() {
    const form = useRef();
    const [name, setName] = useState('');
    const [text, setText] = useState('');
    const [numberInput, setNumberInput] = useState('');
    const [mailLogin, setMailLogin] = useState('');
    const [mailTempateFirst, setMailTempateFirst] = useState('');
    const [mailTempateSecond, setMailTempateSecond] = useState('');
    const [mailKey, setMailKey] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentModalType, setCurrentModalType] = useState('');

    useEffect(() => {
        const fetchGetData = async () => {
            try {
                const getdata = await getData();
                setMailLogin(getdata.mail_login);
                setMailTempateFirst(getdata.mail_template_first);
                setMailTempateSecond(getdata.mail_template_second);
                setMailKey(getdata.mail_key);
            } catch (error) {
                console.error('Error fetching SMS credentials:', error);
            }
        };

        fetchGetData();
    }, []);

    const handleClickFirst = () => {
        setIsModalOpen(true);
        setCurrentModalType('Книга замечаний и предложений');
    };

    const handleClickSecond = () => {
        setIsModalOpen(true);
        setCurrentModalType('Связь с руководителем');
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const sendEmail = (e) => {
        e.preventDefault();

        const template = currentModalType === 'Книга замечаний и предложений' ? mailTempateFirst : mailTempateSecond;

        emailjs
            .sendForm(mailLogin, template, form.current, {
                publicKey: mailKey,
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                },
                (error) => {
                    console.log('FAILED...', error.text);
                }
            );

        setName('');
        setText('');
        setNumberInput('');
        setIsModalOpen(false);
    };

    return (
        <div className="container">
            <div className="contact__content">
                <button onClick={handleClickFirst} className="contact-btn">
                    <p>Книга замечаний и предложений</p>
                </button>
                <button onClick={handleClickSecond} className="contact-btn">
                    <p>Связь с руководителем</p>
                </button>
            </div>

            {isModalOpen && (
                <div className="modal-wrapper">
                    <div className="modal__points height400" style={{height: 268}}>
                        <div className="modal__points-header">
                            <div></div>
                            <div className="modal__points-header-close" onClick={closeModal}></div>
                            <div></div>
                        </div>
                        <div className="contact-form">
                            <p className="contact-form-title">{currentModalType}</p>
                            <form ref={form} onSubmit={sendEmail} className="contact-form">
                                <input
                                    type="text"
                                    name="from_name"
                                    value={name}
                                    onChange={(e) => {
                                        setName(e.target.value);
                                    }}
                                    placeholder="Ваше имя"
                                    className="login-input"
                                />
                                <input
                                    type="text"
                                    name="from_number"
                                    value={numberInput}
                                    onChange={(e) => {
                                        setNumberInput(e.target.value);
                                    }}
                                    placeholder="Ваш номер телефона"
                                    className="login-input"
                                />
                                <textarea
                                    type="text"
                                    name="message"
                                    value={text}
                                    onChange={(e) => {
                                        setText(e.target.value);
                                    }}
                                    placeholder="Ваше сообщение"
                                    className="login-input"
                                />
                                <button className="login__save-btn" type="submit">
                                    Отправить
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Contact;
