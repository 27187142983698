import React, { useState, useEffect } from 'react';
import './header.css';
import Timer from './Timer';
import logo from '../../images/logoImg.jpg'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    selectId,
    selectRole,
    selectNumber,
    changeId,
    changeName,
    changeNumber,
    changeRole,
    changeBonuces,
    selectBonuses,
    changeIsBlocked,
} from '../../features/user/userSlice';
import { registration, loginEmployee, loginUser, getOne, getOneByPhone, changePoints, getSMSCredentials } from '../../http/userApi';
import { getAllByDay, deleteOrder } from '../../http/ordersApi';
import InputMask from 'react-input-mask';
import { createCacheReserved, getThisMonth } from '../../http/cacheReservedApi';
import { getAllSet } from '../../http/settingsApi';

function Header() {
    const currentUserNumber = useSelector(selectNumber);
    const userId = useSelector(selectId)
    const isLogged = !!useSelector(selectRole);
    const role = useSelector(selectRole);
    const points = useSelector(selectBonuses);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [showForm, setShowForm] = useState(false);
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const [nameInput, setNameInput] = useState('');
    const [numberInput, setNumberInput] = useState('');
    const [numberInputValue, setNumberInputValue] = useState('');
    const [passwordInput, setPasswordInput] = useState('');
    const [SMSInput, setSMSInput] = useState('');
    const [isLogin, setIsLogin] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [sureNameInput, setSureNameInput] = useState('');
    const [isChecked, setIsChecked] = useState(true);
    const [showFormSMS, setShowFormSMS] = useState(false);
    const [errorMessageSMS, setErrorMessageSMS] = useState(false);
    const [seconds, setSeconds] = useState(60);
    const [codeSMS, setCodeSMS] = useState('');
    const [modalPointsInfo, setModalPointsInfo] = useState(false);
    const [tripsInfo, setTripsInfo] = useState(false);
    const [fullName, setFullName] = useState('');
    const [isUserLogining, setIsUserLogining] = useState('');
    const [reservedTrips, setReservedTrips] = useState('');
    const [isShowNavbar, setIsShowNavbar] = useState(false);
    const [usernameSms, setUsernameSms] = useState('');
    const [passwordSms, setPasswordSms] = useState('');
    const [priceValue, setPriceValue] = useState(0);
    const [UnSuccessValue, setUnSuccessValue] = useState(0);
    const [SuccessValue, setSuccessValue] = useState(0);
    const [FreeRideValue, setFreeRideValue] = useState(0);

    useEffect(() => {
        const fetchSMSCredentials = async () => {
            try {
                const smsCredentials = await getSMSCredentials();
                setUsernameSms(smsCredentials.username);
                setPasswordSms(smsCredentials.password);
            } catch (error) {
                console.error('Error fetching SMS credentials:', error);
            }
        };
        const fetchDataSet = async () => {
            try {
                const data = await getAllSet();
                const price = data.find(item => item.typeField === 'Цена')?.valueField || 0;
                const UnSuccess = data.find(item => item.typeField === 'Поездку отменил клиент менее чем за 2 часа')?.valueField || 0;
                const Success = data.find(item => item.typeField === 'Успешная поездка')?.valueField || 0;
                const FreeRide = data.find(item => item.typeField === 'Бесплатная поездка')?.valueField || 0;
                setPriceValue(price);
                setUnSuccessValue(UnSuccess)
                setSuccessValue(Success);
                setFreeRideValue(FreeRide);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchDataSet();
        fetchSMSCredentials();
  }, []);

    function handleNavigate() {
        if (role === "ADMIN") {
            navigate('/admin');
        } else if (role === "DRIVER") {
            navigate('/driver');
        } else if (role === "DISPETCHER") {
            navigate('/dispetcher');
        }
    }

    const handleLoginClick = () => {
        setShowForm(!showForm);
        setShowFormSMS(false);
        setErrorMessage('');
        setPasswordInput('');
        // setNumberInput('');
        setSureNameInput('');
        setSMSInput('');
    };

    // const handleLogoutClick = () => {
    //     dispatch(changeName(''))
    //     dispatch(changeNumber(''))        
    // };

    // const handleSaveClick = () => {    
    //     dispatch(changeName(nameInput))
    //     dispatch(changeNumber(numberInput))
    //     dispatch(changeRole("USER"))
    //     setNameInput('');
    //     setNumberInput('');
    //     setPasswordInput('');
    //     setShowForm(false);
    // };

    const handleNumberInputChange = (e) => {
        setNumberInputValue(e.target.value);

        setNumberInput(e.target.value.replace(/[\s()-]/g, ''))
    };

    const handleSMSInputChange = (e) => {
        const inputValue = e.target.value;
        const numbersOnly = /^[\d]*$/;

        if (numbersOnly.test(inputValue)) {
            setSMSInput(inputValue);
        }
    }

    const handlePasswordChange = (e) => {
        const inputValue = e.target.value;
        setPasswordInput(inputValue);
    }

    function generateCode() {
        let result = '';
        for (let i = 0; i < 4; i++) {
            result += Math.floor(Math.random() * 10);
        }
        setCodeSMS(result)
        return result
    }

    function toggleModalPoints() {
        setModalPointsInfo(!modalPointsInfo);
        setTripsInfo(false);
        setShowFormSMS(false);
        setShowForm(false);
        setIsShowNavbar(false);
    }
    function openModalTrips() {
        try {
            const dateNow = new Date();
            getAllByDay(new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate()), new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate() + 7)).then(data => {
                const currentTrips = data.filter(item => item.user.id === userId && item.tripId !== null)
                setReservedTrips(currentTrips);
            })
            setTripsInfo(true);
            setModalPointsInfo(false);
            setShowFormSMS(false);
            setShowForm(false);
            setIsShowNavbar(false);
        } catch (e) {
            console.log(e);
        }
    }
    function closeModalTrips() {
        setTripsInfo(false);
    }

    const signIn = async () => {
        if (!isLogin && !isChecked) return;

        try {
            setFullName(`${nameInput} ${sureNameInput}`);

            if (isLogin) {
                const response = await getOneByPhone(numberInput)
                    .then(data => {
                        if (data.isBlocked === true) {
                            setErrorMessage('Аккаунт по этому номеру телефона заблокирован')
                            return;
                        } else if (data.role === "USER") {
                            const code = generateCode()
                            const currentNumber = numberInput.slice(1);

                            const params = new URLSearchParams();
                            params.append('username', usernameSms);
                            params.append('password', passwordSms);
                            params.append('phone', currentNumber);
                            params.append('text', `Ваш код подтверждения: ${code}`);

                            fetch('https://api.rocketsms.by/simple/send?' + params, {
                                method: 'POST',
                                mode: 'no-cors',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                            })
                                .then(response => {
                                    startTimer();
                                    setShowForm(false);
                                    setShowFormSMS(true);
                                    setIsUserLogining(true);
                                    setErrorMessage('');
                                })
                                .catch(error => {
                                    setErrorMessage(error.response.data.message)
                                });
                        } else {
                            setShowForm(false);
                            setShowFormSMS(true);
                            setIsUserLogining(false);
                            setErrorMessage('');
                        }
                    })
                    .catch(error => {
                        setErrorMessage(error.response.data.message)
                    });
            } else {
                const response = await getOne(numberInput)

                if (response === 'ok') {
                    setErrorMessage('Пользователь с таким номером телефона существует')
                }
                else if (!nameInput) {
                    setErrorMessage('Заполните имя')
                }
                else if (!sureNameInput || sureNameInput.length < 3) {
                    setErrorMessage('Фамилия должна содержать минимум 3 символа')
                }
                else if (numberInput.length !== 13) {
                    setErrorMessage('Не верный формат номера')
                }
                else if (response === 'not found') {
                    const code = generateCode()
                    const currentNumber = numberInput.slice(1);

                    const params = new URLSearchParams();
                    params.append('username', usernameSms);
                    params.append('password', passwordSms);
                    params.append('phone', currentNumber);
                    params.append('text', `Ваш код подтверждения: ${code}`);

                    fetch('https://api.rocketsms.by/simple/send?' + params, {
                        method: 'POST',
                        mode: 'no-cors',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                        .then(response => {
                            startTimer();
                            setShowForm(false);
                            setShowFormSMS(true);
                            setErrorMessage('');
                        })
                        .catch(error => {
                            setErrorMessage(error.response.data.message)
                        });
                }
            }
        } catch (error) {
            console.log(error)
            setErrorMessage(error.response.data.message)
        }
    }

    async function registrationFinish() {
        if (SMSInput === codeSMS) {
            const data = await registration(fullName, numberInput);

            dispatch(changeId(data.id))
            dispatch(changeName(data.name))
            dispatch(changeNumber(data.phoneNumber))
            dispatch(changeRole(data.role))
            dispatch(changeBonuces(data.points))
            dispatch(changeIsBlocked(data.isBlocked))
            setShowFormSMS(false);
            setErrorMessage('');
            setPasswordInput('');
            setNumberInput('');
            setSureNameInput('');
            setSMSInput('');
        } else {
            setErrorMessageSMS(true);
        }
    }

    const logOut = () => {
        dispatch(changeName(''))
        dispatch(changeNumber(''))
        dispatch(changeRole(''))
        localStorage.clear();
        setIsShowNavbar(false);
    }

    async function signInFinish() {
        if (isLogin) {
            if (SMSInput === codeSMS) {
                // if (SMSInput === '1234') {
                const data = await loginUser(numberInput);

                dispatch(changeId(data.id))
                dispatch(changeName(data.name))
                dispatch(changeNumber(data.phoneNumber))
                dispatch(changeRole(data.role))
                dispatch(changeBonuces(data.points))
                dispatch(changeIsBlocked(data.isBlocked))
                setErrorMessageSMS(false);
                setShowFormSMS(false);
                setErrorMessage('');
                setPasswordInput('');
                setNumberInput('');
                setNameInput('');
                setSureNameInput('');
            } else {
                setErrorMessageSMS(true);
            }
        } else if (!isLogin) {
            if (SMSInput === codeSMS) {
                // if (SMSInput === '1234') {
                const data = await registration(numberInput);

                dispatch(changeId(data.id))
                dispatch(changeName(data.name))
                dispatch(changeNumber(data.phoneNumber))
                dispatch(changeRole(data.role))
                dispatch(changeBonuces(data.points))
                dispatch(changeIsBlocked(data.isBlocked))
                setErrorMessageSMS(false);
                setShowFormSMS(false);
                setErrorMessage('');
                setPasswordInput('');
                setNumberInput('');
                setNameInput('');
                setSureNameInput('');
            } else {
                setErrorMessageSMS(true);
            }
        }
    }

    async function signInEmployee() {
        try {
            const data = await loginEmployee(numberInput, passwordInput);
            dispatch(changeId(data.id))
            dispatch(changeName(data.name))
            dispatch(changeNumber(data.phoneNumber))
            dispatch(changeRole(data.role))
            dispatch(changeBonuces(data.points))
            setErrorMessageSMS(false);
            setShowFormSMS(false);
            setErrorMessage('');
            setPasswordInput('');
            setNumberInput('');
            setNameInput('');
            setSureNameInput('');
        } catch (error) {
            console.log(error)
            setErrorMessage(error.response.data.message)
        }
    }

    function startTimer() {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
        }, 1000);
    }

    function handleLogRegFormChange() {
        setIsLogin(!isLogin);
        setErrorMessage('');
        setPasswordInput('');
        setNumberInput('');
        setNameInput('');
        setSureNameInput('');
    }

    function privacyPolicyClick() {
        setShowPrivacyPolicy(true);
    }

    function closePrivacyPolicy() {
        setShowPrivacyPolicy(false);
    }

    async function handleCancelTrip(trip) {
        
        deleteOrder({ id: trip.id }).then(data => {
            const dateNow = new Date();
            const tripDate = new Date(trip.dateTimeStart);
            let diff = (tripDate.getTime() - dateNow.getTime()) / 1000;
            diff /= (60 * 60);
    
            if (diff < 2) {
                // Calculate the points to deduct
                const pointsToDeduct = trip.seats * UnSuccessValue; // 100 points per seat cancellation -100 баллов при отмене менее чем за 2 часа
    
                changePoints({ userId: userId, action: 'decreaseCanceled', points: pointsToDeduct })
                    .then(data => {
                        getOneByPhone(currentUserNumber).then(data => {
                            dispatch(changeBonuces(data.points));
                        });
                    });
            }
    
            getAllByDay(new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate()), new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate() + 7)).then(data => {
                const currentTrips = data.filter(item => item.user.id === userId && item.tripId !== null);
                setReservedTrips(currentTrips);
            }).then(() => {
                createCacheReserved({ userId: userId, dateTimeStart: null, dateTimeStartM: trip.dateTimeStart });
            });
        });
    }

    function toggleModalNavbar() {
        setIsShowNavbar(false);
    }

    return (
        <div className="container">
            <div className="header__content">
                <div className="logo"><img className='logoImg' src={logo} alt="Logo" /></div>
                {
                    role === "USER" ?
                        '' :
                        role === "ADMIN" ?
                            <div className='header-btn' onClick={handleNavigate}>АДМИН</div> :
                            role === "DRIVER" ?
                                <div className='header-btn' onClick={handleNavigate}>ВОДИТЕЛЬ</div> :
                                    role === "DISPETCHER" ?
                                    <div className='header-btn' onClick={handleNavigate}>ОПЕРАТОР</div> :
                                ''
                }
                <div className='header_btns-wrap hidden'>
                    {role === "USER" && <div className='header_points-info' onClick={toggleModalPoints}>Мои бонусы ({points})</div>}
                    {role === "USER" && <div className='header_points-info' onClick={openModalTrips}>Мои поездки</div>}
                    {isLogged ?
                        <div className='logout-btn header-btn' onClick={logOut}>Выйти</div>
                        :
                        <div className='login-btn header-btn' onClick={handleLoginClick}>Войти</div>
                    }
                </div>
                {isLogged && <div className='hiddenAdapt shown open__modal-navbar' onClick={() => { setIsShowNavbar(!isShowNavbar) }}></div>}
                {!isLogged && <div className='login-btn header-btn hiddenLogin shownLogin' onClick={handleLoginClick}>Войти</div>}
            </div>
    
            {isShowNavbar && <div className='modal__navbar'>
                <div className='modal__navbar-close' onClick={toggleModalNavbar}></div>
                {role === "USER" && <div className='header_points-info header_points-info-mob' onClick={toggleModalPoints}>Мои бонусы ({points})</div>}
                {role === "USER" && <div className='header_points-info header_points-info-mob' onClick={openModalTrips}>Мои поездки</div>}
                {isLogged ?
                    <div className='logout-btn header-btn header_points-info-mob' onClick={logOut}>Выйти</div>
                    :
                    <div className='login-btn header-btn header_points-info-mob' onClick={handleLoginClick}>Войти</div>
                }
            </div>}
    
            {modalPointsInfo && (
                <div className='modal-wrapper'>
                    <div className='modal__points'>
                        <div className='modal__points-header'>
                            <div></div>
                            <div className='modal__points-header-info'><p>На балансе {points} бонусов</p></div>
                            <div className='modal__points-header-close' onClick={toggleModalPoints}></div>
                        </div>
                        <div className='modal__points-content'>
                            <p className="tiket-text2">Бонусы мы начисляем за каждую отплаченную поездку. <span className="ticket-text-selected">1р = 5 бонусов</span>. После завершения каждой поездки, на баланс вашего аккаунта будет начисленно <span className="ticket-text-selected">{SuccessValue} бонусов</span>, которые вы сможете использовать на бесплатную поездку.</p>
                            <p className="tiket-text3">{FreeRideValue} бонусов = 1 бесплатная поездка</p>
                            <p className="tiket-text3">За счет баллов может быть забронирована 1 поездка на 1 пассажира. 
                            Возможность сделать бронь за счет баллов восстанавливается после совершения поездки за баллы или отмены такой брони.
                            За поездку, оплаченную баллами, не начисляются новые баллы. Бронирование поездки за баллы не влияет на начисление баллов за другие поездки.
                            </p>
                            <p className="tiket-text4">Если вы отменили поездку позднее чем <span className="ticket-text-selected">за 2 часа</span> до отправления, у вас на балансе сгорит <span className="ticket-text-selected">{UnSuccessValue} бонусов</span>. В случае если вы не отменили поездку и не явились на посадку, с вашего баланса будет списано <span className="ticket-text-selected">200 бонусов</span>. В иных случаях бонусы не сгорают.</p>
                        </div>
                    </div>
                </div>
            )}
    
            {tripsInfo && (
                <div className='modal-wrapper'>
                    <div className='modal__points height400'>
                        <div className='modal__points-header'>
                            <div></div>
                            <div className='modal__points-header-close' onClick={closeModalTrips}></div>
                            <div></div>
                        </div>
                        {reservedTrips ? (
                            reservedTrips.filter(trip => trip.status === 'Забронировано').length > 0 ? (
                                reservedTrips.filter(trip => trip.status === 'Забронировано').map(trip => {
                                    const tripStartDate = new Date(trip.dateTimeStart);
                                    const tripFinishDate = new Date(trip.trip.dateTimeFinish);

                                    const tripYear = tripStartDate.getFullYear();
                                    const tripMonth = tripStartDate.getMonth() + 1;
                                    const tripDay = tripStartDate.getDate();

                                    const tripStarthours = tripStartDate.getHours();
                                    const tripStartMinutes = tripStartDate.getMinutes();
                                    const tripFinishhours = tripFinishDate.getHours();
                                    const tripFinishMinutes = tripFinishDate.getMinutes();

                                    const tripStart = (`${tripStarthours}`.length === 1 ? `0${tripStarthours}` : `${tripStarthours}`) + ':' + (`${tripStartMinutes}`.length === 1 ? `0${tripStartMinutes}` : `${tripStartMinutes}`);
                                    const tripEnd = `${tripFinishhours}`.length === 1 ? `0${tripFinishhours}` : `${tripFinishhours}` + ':' + (`${tripFinishMinutes}`.length === 1 ? `0${tripFinishMinutes}` : `${tripFinishMinutes}`);

                                    const startTime = new Date('1970-01-01T' + tripStart + 'Z');
                                    const endTime = new Date('1970-01-01T' + tripEnd + 'Z');

                                    const timeDiff = endTime - startTime;
                                    const hours = Math.floor((timeDiff % 86400000) / 3600000);
                                    const minutes = Math.round(((timeDiff % 86400000) % 3600000) / 60000);

                                    const difference = `${hours} часа ${minutes} мин`;

                                    return (
                                        <div key={trip.id} className={`reservationTickets__card`}>
                                            <p className='modal__points-date'>{`${tripYear}.${`${tripMonth}`.length === 1 ? `0${tripMonth}` : `${tripMonth}`}.${`${tripDay}`.length === 1 ? `0${tripDay}` : `${tripDay}`}`}</p>
                                            <div className="reservationTickets__card-header noCursorPointer">
                                                <div className="reservationTickets__card-header-left">
                                                    <div className="departure-arrival">
                                                        <div className="time">{tripStart}</div>
                                                        <div className="place">{trip.startStop.name}</div>
                                                    </div>

                                                    <div className="departure-difference">
                                                        <p className="departure-difference-text">{difference}</p>
                                                    </div>

                                                    <div className="departure-arrival">
                                                        <div className="time">{tripEnd}</div>
                                                        <div className="place">{trip.trip.routeId === 1 ? 'Поставы' : 'Минск'}</div>
                                                    </div>
                                                </div>
                                                <div className="reservationTickets__card-header-right">
                                                    <p className="trip_price">{+trip.seats * priceValue}р.00к.</p>
                                                </div>
                                            </div>
                                            <div className='modal__points-cancel-btn' onClick={() => handleCancelTrip(trip)}><p className='modal__points-cancel-btn-text'>Отменить</p></div> {/* тут */}
                                        </div>
                                    );
                                })
                            ) : (
                                <div className='no-reserved-trips-info'>
                                    У вас нет забронированных рейсов.
                                </div>
                            )
                        ) : (
                            <div className='no-reserved-trips-info'>
                                У вас нет забронированных рейсов.
                            </div>
                        )}
                    </div>
                </div>
            )}

    
            {showForm && (
                <div className='modal-wrapper' onClick={handleLoginClick}>
                    <div className='login-form' onClick={e => e.stopPropagation()}>
                        {!isLogin && <input
                            type="text"
                            value={nameInput}
                            onChange={(e) => { setNameInput(e.target.value) }}
                            placeholder="Введите ваше имя"
                            className='login-input'
                        />}
                        {!isLogin && <input
                            type="text"
                            value={sureNameInput}
                            onChange={(e) => { setSureNameInput(e.target.value) }}
                            placeholder="Введите вашу фамилию"
                            className='login-input'
                        />}
                        <InputMask className='login-input' value={numberInputValue} onChange={(e) => { handleNumberInputChange(e) }} placeholder="Введите ваш телефон" mask="+375 (99) 999-99-99" maskChar="_" />
                        {!isLogin && <div className='login_btn-addWrap'><p className='login_btn-addText'>Нажимая на кнопку вы соглашаетесь с <a className='login_btn-addSpan' href='#' onClick={privacyPolicyClick}>политикой конфиденциальности</a> <input type="checkbox" checked={isChecked} onChange={() => { setIsChecked(!isChecked) }} /></p></div>}
                        {errorMessage && <p className='login__error-text'>{errorMessage}</p>}
                        <button className='login__save-btn' onClick={() => signIn()}>Продолжить</button>
                        <p className='login__save-info'>{isLogin ? 'Нет аккаунта?' : 'Есть аккаунт?'} <span className='login__ask-span' onClick={() => handleLogRegFormChange()}>{isLogin ? 'Зарегестрируйтесь!' : 'Войдите!'}</span></p>
                    </div>
                </div>
            )}
    
            {showPrivacyPolicy && (
                <div className='modal-wrapper'>
                    <div className='modal__points'>
                        <h2 className='page__privacyPolicy-header'>Политика конфиденциальности</h2>
                        <div className='page__privacyPolicy-btn' onClick={closePrivacyPolicy}>Закрыть</div>
                    </div>
                </div>
            )}
    
            {showFormSMS && isUserLogining && (
                <div className='modal-wrapper' onClick={handleLoginClick} data-id-test2>
                    <div className='login-form' onClick={e => e.stopPropagation()}>
                        <div className='login-form-sms-wrap'><p className='login-form-sms-info'>Вам на телефон был отправлен код подтверждения.</p></div>
                        <Timer number={numberInput} generateCode={generateCode} />
                        <input
                            type="text"
                            value={SMSInput}
                            onChange={handleSMSInputChange}
                            placeholder="Код подтверждения"
                            className='login-input'
                        />
                        {errorMessageSMS && <p className='login__error-text'>Неверный код</p>}
                        <button className='login__save-btn' onClick={() => signInFinish()}>{isLogin ? 'Войти' : 'Зарегестрироваться'}</button>
                    </div>
                </div>
            )}
    
            {showFormSMS && !isUserLogining && (
                <div className='modal-wrapper' onClick={handleLoginClick} data-id-test1>
                    <div className='login-form' onClick={e => e.stopPropagation()}>
                        {isLogin && <input
                            type="text"
                            value={passwordInput}
                            onChange={(e) => { setPasswordInput(e.target.value) }}
                            placeholder="Введите ваш пароль"
                            className='login-input'
                        />}
                        {errorMessage && <p className='login__error-text'>{errorMessage}</p>}
                        <button className='login__save-btn' onClick={() => signInEmployee()}>{isLogin ? 'Войти' : 'Зарегестрироваться'}</button>
                    </div>
                </div>
            )}
    
            {showFormSMS && !isLogin && (
                <div className='modal-wrapper' style={{zIndex: 1000}}>
                <div className='login-form'>
                    <div className='login-form-sms-wrap'><p className='login-form-sms-info'>Вам на телефон был отправлен код подтверждения.</p></div>
                    <Timer number={numberInput} generateCode={generateCode} />
                    <input
                        type="text"
                        value={SMSInput}
                        onChange={handleSMSInputChange}
                        placeholder="Код подтверждения"
                        className='login-input'
                    />
                    {errorMessageSMS && <p className='login__error-text'>Неверный код</p>}
                    <button className='login__save-btn' onClick={() => registrationFinish()}>{isLogin ? 'Войти' : 'Зарегестрироваться'}</button>
                </div>
                </div>
            )}
        </div>
    );
    
}

export default Header;
