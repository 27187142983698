import React, { useState, useEffect } from 'react';
import { getAllSet, changeSet } from '../../../http/settingsApi';
import './modal.css';

function SettingsModal(props) {
    const { handleCloseAll } = props;
    const [settings, setSettings] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const data = await getAllSet();
                setSettings(data);
                setLoading(false);
            } catch (error) {
                console.error('Ошибка при получении настроек:', error);
                setLoading(false);
            }
        };
        fetchSettings();
    }, []);

    const handleSettingChange = (index, newValue) => {
        const updatedSettings = [...settings];
        updatedSettings[index].valueField = newValue;
        setSettings(updatedSettings);
    };

    const handleSaveSetting = async (index) => {
        const { typeField, valueField } = settings[index];
        try {
            await changeSet(typeField, valueField);
            alert('Настройка успешно сохранена');
        } catch (error) {
            console.error('Ошибка при сохранении настройки:', error);
            alert('Ошибка при сохранении настройки');
        }
    };

    return (
        <div className='admin__modal-wrapper'>
            <div className='admin__modal-container'>
                <div className='admin__modal-close' onClick={() => handleCloseAll()}></div>
                <div className='admin___modal-change no-border'>
                    <h3 className='admin___modal-title'>Основные настройки</h3>
                    {loading ? (
                        <p>Загрузка...</p>
                    ) : (
                        settings.map((setting, index) => (
                            <div key={setting.id} className='admin___modal-wrap'>
                                <div className='settings__field'>
                                    <p>{setting.typeField}</p> 
                                    <input
                                        type="number"
                                        value={setting.valueField}
                                        onChange={e => handleSettingChange(index, e.target.value)}
                                        placeholder={setting.typeField}
                                        className='admin_modal-input'
                                    />
                                </div>
                                <div
                                    className='admin__modal-btn admin__modal-success'
                                    onClick={() => handleSaveSetting(index)}
                                >
                                    &#10003;
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </div>
    );
}

export default SettingsModal;
