import './usersModal.css'
import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAllUsers, blockUser } from '../../../http/userApi';
import { getAll } from '../../../http/cacheReservedApi';


function UsersModal(props) {
    const dispatch = useDispatch()  
    const {handleCloseAll} = props;
    const [allUsers, setAllUsers] = useState('');  
    const [countClick, setCountClick] = useState('');
    const [searchInput, setSearchInput] = useState('');
    const [comment, setComment] = useState('');
    const [cacheArray, setCacheArray] = useState([]);
    let actualUsersArr = [];
    
    useEffect(() => {
        getAllUsers('USER')
        .then(data => {
            setAllUsers(data);
            actualUsersArr = data;            
            
            for (let i = 0; i < data.length; i++) {                
                getAll(data[i].id)
                .then(data => {
                    setCacheArray(prevCacheArray => [...prevCacheArray, data]);
                })  
            }                           
        })
    }, [])

    function handleBlockUser(user) {
        if (countClick !== user.id) {
            setCountClick(user.id);
        }
        else if (countClick === user.id) {
            setCountClick('');
            blockUser({phoneNumber: user.phoneNumber, blockComment: comment}).then(data => {alert(data.message)});
        }        
    }

    function handleSearchByPhone() {
        const fileredUsers = allUsers.filter(user => user.phoneNumber.includes(searchInput));
        setAllUsers(fileredUsers);
    }

    function handleRefreshUsers() {
        getAllUsers('USER').then(data => {setAllUsers(data);})   
        setSearchInput('');
    }

    function getSuccess(id) {        
        return cacheArray.reduce((acc, arr) => acc + arr.filter(item => item.userId === id && item.dateTimeStart).length, 0);
    }

    function getMissed(id) {
        return cacheArray.reduce((acc, arr) => acc + arr.filter(item => item.userId === id && item.dateTimeStartM).length, 0);
    }

    return (       
        <div className='admin__modal-wrapper'> 
                         
            <div className='admin__modal-container'>            
                <div className='admin__modal-close' onClick={() => handleCloseAll()}></div>  
                <div className='usersModal__search-wrap'>
                    <input className='usersModal__search-input' value={searchInput} onChange={(e) => {setSearchInput(e.target.value)}}/>
                    <div className='usersModal__search-btns'>
                        <div className='admin__modal-btn admin__modal-success usersModal__search-btn' onClick={handleSearchByPhone}>Найти</div>
                        <div className='admin__modal-btn admin__modal-success usersModal__search-btn' onClick={handleRefreshUsers}>Сбросить</div>
                    </div>
                </div>
                
                {
                    allUsers && allUsers.map(user => {
                        return <div key={user.id} className='users__modal-elem-wrap'>
                            <div>
                                <p className='users__modal-elem-item'>Имя: {user.name}</p>
                                <p className='users__modal-elem-item'>Номер телефона: {user.phoneNumber}</p>
                                <p className='users__modal-elem-item'>Баллов: {user.points}</p>
                                <p className='users__modal-elem-item'>Успешных: {cacheArray ? getSuccess(user.id) : '..'}</p>
                                <p className='users__modal-elem-item'>Пропущеных: {cacheArray ? getMissed(user.id) : '..'}</p>
                                {user.isBlocked && <p className='users__modal-elem-item'>Заблокирован</p>}
                            </div>
                            <div className='admin__modal-btn admin__modal-danger' onClick={() => handleBlockUser(user)}>Заблокировать</div>
                            {countClick === user.id && <div>
                                <textarea                                    
                                    className='blockComment'
                                    placeholder='Комментарий..'
                                    value={comment}
                                    onChange={(e) => {setComment(e.target.value)}}
                                />
                                <p>Заблокированный пользователь не сможет пользоваться функционалом сайта. Для подтверждения нажмите кнопку еще раз.</p>
                            </div>}
                        </div>
                    })
                }
            </div> 
        </div>                    
    )
}

export default UsersModal;