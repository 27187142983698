import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { increaseSeatsRemain, decreaseSeatsRemain, setSeatsRemain, selectSeatsRemain } from '../../features/driverDetails/driverDetailsSlice';
import { setTripId, selectTripId } from '../../features/tripDetails/tripDetailsSlice';

import './reservationTickets.css'
import moment from 'moment';
import { getByBus } from '../../http/tripsApi';
import {
  selectId,
  selectRole,
} from '../../features/user/userSlice'
import { getBus } from '../../http/busesApi'
import { getAllStops } from '../../http/stopsApi';
import { getAllByTrip, deleteOrder, updateOrder } from '../../http/ordersApi';
import { changePoints } from '../../http/userApi';
import { createCacheReserved } from '../../http/cacheReservedApi';
import Modal from '../../components/UI/Modal/Modal';
import Toggle from '../../components/UI/Toggle/Toggle';
import { Link } from 'react-router-dom';



const PassengerCards = ({
  darkTheme,
  setDarkTheme,
  seatsCount,
  setSeatsCount,
  setPassengersFromList
}) => {
  const dispatch = useDispatch();
  const driverId = useSelector(selectId);
  const seatsRemain = useSelector(selectSeatsRemain)
  const tripId = useSelector(selectTripId)
  const isDriver = useSelector(selectRole) === 'DRIVER';
  const [nearestTrip, setNearestTrip] = useState();
  const [allTrips, setAllTrips] = useState([]);
  const [allStops, setAllStops] = useState();
  const [orders, setOrders] = useState();
  const [formattedData, setFormattedData] = useState('');
  const [countReserved, setCountReserved] = useState(0);
  
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false)
  const [modalMode, setModalMode] = useState(0)
  const [otherTodayOrders, setOtherTodayOrders] = useState([]);
  const [nextDayTrips, setNextDayTrips] = useState([]);

  const [showNextDayTrips, setShowNextDayTrips] = useState(false);
  const [passengersFromList, setPassengersList] = useState(0);

  const [error, setError] = useState(null);
  
  useEffect(() => {
    
    if (!isDriver) return

    getBus(driverId).then(data => {
      getByBus(data.id).then(data => {
        const now = new Date();

        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow.setHours(0, 0, 0, 0);

        const nextDay = new Date();
        nextDay.setDate(nextDay.getDate() + 2);
        nextDay.setHours(0, 0, 0, 0);

        let currentTrip = {};
        let diffCompare = '';
        data.forEach((dataOne) => {

          const dateOneStart = new Date(dataOne.dateTimeStart);
          if (dateOneStart > today && dateOneStart < tomorrow) {
            if (allTrips && !allTrips.some(obj => obj.id === dataOne.id)) {
              setAllTrips(currentTrips => [...currentTrips, dataOne]);
            }
          }
          if (dateOneStart > tomorrow && dateOneStart < nextDay) {
            if (nextDayTrips && !nextDayTrips.some(obj => obj.id === dataOne.id)) {
              setNextDayTrips(currentTrips => [...currentTrips, dataOne]);
            }
          }

          let dateCheck = +(new Date(dataOne.dateTimeFinish));
          let diff = dateCheck - now;

          if (diff < 0) {
            return;
          } else if (!diffCompare) {
            diffCompare = +(new Date(dataOne.dateTimeFinish)) - now
            currentTrip = dataOne;
          } else if (diffCompare > diff) {
            diffCompare = diff;
            currentTrip = dataOne;
          }
        })

        if (currentTrip.id) {
          const maxSeats = data.find(item => item.id === currentTrip?.id).maxSeats
          setSeatsCount(maxSeats)
          dispatch(setTripId(currentTrip.id))
        }




        showResered(currentTrip);
        setNearestTrip(currentTrip);

        setFormattedData(moment(currentTrip.dateTimeStart).format("YYYY-MM-DD"))

        
        try {
          console.log(currentTrip)
          const seatsFromList = currentTrip.withListSeats || 0; // Убедитесь, что по умолчанию стоит 0
          setPassengersFromList(seatsFromList);
          setPassengersList(seatsFromList)
          if (currentTrip.id) {
            getAllStops(currentTrip.routeId).then(data => {
              setAllStops(data);
              /* console.log(data); */
            });

            getAllByTrip(currentTrip.id).then(data => {
              const reservedSeats = data.reduce(function (sum, elem) {
                return sum + elem.seats;
              }, 0);

              checkLocalRemainedSeats(currentTrip.id, reservedSeats);

              for (let i = 0; i < data.length; i++) {
                let seatsCount = data[i].seats;
                data[i].seatsReservedHeandled = [];
                data[i].heandled = false;
                data[i].orderActions = [];

                while (seatsCount > 0) {
                  seatsCount = seatsCount - 1;
                  data[i].seatsReservedHeandled.push(false);
                }
              }

              checkLocalCurrentOrders(data);
            });
          }else{
            alert('На данный момент рейсов нету')
          }



        } catch (error) {
          /* alert(error) */
        }
      })
    })

  }, [isDriver])

  function checkLocalRemainedSeats(id, reservedSeats) {

    if (!localStorage.getItem('remainSeats')) {
      localStorage.setItem('remainSeats', JSON.stringify({ tripId: id, remainingSeats: seatsCount - reservedSeats }))
    } else {
      let remainSeats = JSON.parse(localStorage.getItem('remainSeats'));

      if (id === remainSeats.tripId) {
        dispatch(setSeatsRemain(remainSeats.remainingSeats))
      } else {

        dispatch(setSeatsRemain(seatsCount - reservedSeats))
        localStorage.setItem('remainSeats', JSON.stringify({ tripId: id, remainingSeats: seatsCount - reservedSeats }))
      }
    }
  }

  function checkLocalCurrentOrders(data) {
    // console.log(orders);


    if (!localStorage.getItem('currentOrders')) {
      localStorage.setItem('currentOrders', JSON.stringify(data))
    } else {
      let localCurrentOrders = JSON.parse(localStorage.getItem('currentOrders'));

     /*  console.log(localCurrentOrders[0]?.tripId);
      console.log(data[0]?.tripId); */
      if (localCurrentOrders[0]?.tripId === data[0]?.tripId) {
        /* console.log('эта поездка уже заргужалась'); */
        setOrders(data.map((order, index) => {
          if(localCurrentOrders[index].heandled) {
            return order = { ...localCurrentOrders[index] }
          } else { return order }
        }
        ))
      } else {
       /*  console.log('эта новая поездка'); */
        localStorage.setItem('currentOrders', JSON.stringify(data))
        setOrders(data)
      }
    }
  }

  /* тут */
  async function showTripDetails(id) {
    setIsLoading(true);
    setModalMode(2);
    openModal();
  
    try {
      const data = await getAllByTrip(id);
      setOtherTodayOrders(data);
    } catch (error) {
      console.error('Ошибка при загрузке деталей поездки:', error);
      setError('Неверно задан рейс'); // Устанавливаем сообщение об ошибке
      setOtherTodayOrders([]);
    } finally {
      setIsLoading(false);
    }
  }

  async function sendHandledOrder(currentOrder) {
    try {
      let payedFor = currentOrder.user.payedFor ? currentOrder.user.payedFor : false

      for (let i = 0; i < currentOrder.orderActions.length; i++) {

        if (currentOrder.orderActions[i] === 'decrease') {
          await changePoints({ userId: currentOrder.user.id, action: 'decrease' })
        }
        if (currentOrder.orderActions[i] === 'increase') {
          if (payedFor) {
            await changePoints({ userId: currentOrder.user.id, action: 'payedByPoints' })
            payedFor = false
          } else {
            await changePoints({ userId: currentOrder.user.id, action: 'increase' })
          }

        }
      }

      if (currentOrder.orderActions.find(item => item === 'decrease')) {
        /* удаление */
        createCacheReserved({ userId: currentOrder.user.id, dateTimeStart: null, dateTimeStartM: currentOrder.dateTimeStart })
        /* await deleteOrder({ id: currentOrder.id }) */
        await updateOrder({ 
          id: currentOrder.id, 
          status: 'Отменено'  // Добавляем статус
        });
      } else {
        /* принять что пришёл */
        createCacheReserved({ userId: currentOrder.user.id, dateTimeStart: currentOrder.dateTimeStart, dateTimeStartM: null })
        /* await deleteOrder({ id: currentOrder.id }) */
        await updateOrder({ 
          id: currentOrder.id, 
          status: 'Сел'  // Добавляем статус
        });
      }

      
    } catch (error) {
      console.log(error)
    }
  }

  function handleArrive(currentOrder) {
    setOrders(
      orders.map((order) => {
        if (order.id === currentOrder.id) {
          order.seatsReservedHeandled.shift()
          order.orderActions.push('increase')
          if (order.seatsReservedHeandled.length === 0) order.heandled = true
          
          return order
        } else {
          return order
        }
      })
    )
    localStorage.setItem('currentOrders', JSON.stringify(orders))

    if (!currentOrder.heandled) return

    sendHandledOrder(currentOrder)

  }

  function handleMissed(currentOrder) {

    setOrders(
      orders.map((order) => {
        if (order.id === currentOrder.id) {

          order.seatsReservedHeandled.shift()
          order.orderActions.push('decrease')
          if (order.seatsReservedHeandled.length === 0) order.heandled = true

          return order
        } else {
          return order
        }
      })
    )

    localStorage.setItem('currentOrders', JSON.stringify(orders))

    localStorage.setItem('remainSeats', JSON.stringify({ tripId: currentOrder.tripId, remainingSeats: seatsRemain + 1 }))
    dispatch(increaseSeatsRemain());


    if (!currentOrder.heandled) return

    sendHandledOrder(currentOrder)

  }

  const getPhoneLink = (phone) => {
    return `tel:${phone}`
  }

  const getPassengers = (order, actions = true) => {

    return <div key={order.id} className="driver__passenger">
      {actions && <div className='driver__passenger-buttons'>
        {
          order.seatsReservedHeandled.map((seat) => (
            <button key={Date.now() + '' + Math.random()} onClick={() => { handleMissed(order) }} className='driver__passenger-btn'>&#10006;</button>
          ))
        }
      </div>
      }
      <div className={`driver__passenger-info ${order.heandled ? order.orderActions.filter(item=> item === 'increase').length=== 0? 'handled negative' : 'handled' : ''}`}>
        {!actions ? <p>Остановка: {order.startStop?.name}</p> : ''}
        <p>Имя: {order.user.name}</p>
        <p>Телефон: <a href={getPhoneLink(order.user.phoneNumber)}>{order.user.phoneNumber}</a></p>
        <p>Мест: <b>{order.heandled ? order.orderActions.filter(item=> item === 'increase').length :order.seats}</b></p>
        {order.comment ? <p>Комментарий: <span className='driver__passenger-info-comment'>{order.comment}</span></p> : ''}
        {order.user.payedFor === order.tripId && <p className='driver_ticker-selected'>1 место оплачено баллами</p>}
      </div>
      {actions && <div className='driver__passenger-buttons'>
        {
          order.seatsReservedHeandled.map((seat) => (
            <button key={Date.now() + '' + Math.random()} onClick={() => { handleArrive(order) }} className='driver__passenger-btn test1'>&#10004;</button>
          ))
        }
      </div>
      }
    </div>
  }
  /* тут */
  async function showResered(trip) {
    try {
      const data = await getAllByTrip(trip.id);
      let count = data.reduce((total, order) => total + Number(order.seats), 0);
      setCountReserved(count);
    } catch (error) {
      /* console.error('Ошибка при загрузке зарезервированных мест:', error); */
      // Здесь можно добавить обработку ошибок, например, показ сообщения об ошибке пользователю
    }
    // setShowReseredId(trip.id);
    // setTimeout(() => {
    //   setShowReseredId(false);
    // }, 60000) 
  }

  function openModal() {
    setShowModal(true)
    document.body.style.overflow = 'hidden'
  }

  function closeModal() {
    setShowModal(false)
    setModalMode(0)
    setOtherTodayOrders([])
    document.body.style.overflow = ''
  }

  return (
    <div className={`driver__container ${darkTheme ? 'dark' : ''}`}>
      
      <div className='driver__all-trips-wrap'>
        <div className='driver__all-trips-item driver__all-trips-item_title'>
          <p className='driver__all-trips-text driver__all-trips-text-b'>Рейсы на сегодня {formattedData}</p>
          <div className='driver__all-trips-btn'
            onClick={() => {
              setModalMode(1)
              openModal()
            }}
          ><span></span><span></span><span></span></div>
        </div>
        {(allTrips.length > 0) && allTrips.map((trip) => (
          <div key={trip.id}
            onClick={() => {
              if (trip.id !== nearestTrip.id) showTripDetails(trip.id)
            }}
            className={`driver__all-trips-item driver__all-trips-item_trip ${trip.id === nearestTrip.id ? 'driver__all-trips-item-sel' : ''}`}>
            <p className='driver__all-trips-text'>{`${trip.routeId === 1 ? 'Минск-Поставы' : 'Поставы-Минск'} ${moment(trip.dateTimeStart).format("HH:mm:ss")}`}</p>
            {trip.id === nearestTrip.id && 
            <p className='driver__all-trips-count'>
                ({countReserved + passengersFromList}/15)
            </p>}
          </div>
        ))}
        {showNextDayTrips && <>
          <div className='driver__all-trips-item driver__all-trips-item_title'>
            <p className='driver__all-trips-text driver__all-trips-text-b'>Рейсы на завтра</p>
          </div>
          {nextDayTrips && nextDayTrips.map((trip) => (
            <div key={trip.id}
              onClick={() => { showTripDetails(trip.id) }}
              className={`driver__all-trips-item driver__all-trips-item_trip ${trip.id === nearestTrip.id ? 'driver__all-trips-item-sel' : ''}`}>
              <p className='driver__all-trips-text'>{`${trip.routeId === 1 ? 'Минск-Поставы' : 'Поставы-Минск'} ${moment(trip.dateTimeStart).format("HH:mm:ss")}`}</p>
              {trip.id === nearestTrip.id && 
              <p className='driver__all-trips-count'>
                  ({countReserved + passengersFromList}/15)
              </p>}
            </div>
          ))
          }
        </>
        }
      </div>

      <p className='driver__container-data'>Остановки:</p>
      <p><b>Количество пассажиров с листа: {passengersFromList}</b></p>
      {allStops && allStops.map((stop) => (
        <div key={stop.name} className="driver__card">
          <h2>{stop.name}</h2>
          {orders && orders
            .filter((order) => order.startStopId === stop.id)
            .map((order) => (getPassengers(order)))}
        </div>
      ))}
      
      {modalMode > 0 && <Modal visible={showModal} setVisible={closeModal}>
        {modalMode === 1 ?
          <div className='modal__content'>
            <div className='modal__row'>
              <div className='modal__title'>
                Сменить тему?
              </div>
              <Toggle checked={darkTheme} setChecked={setDarkTheme} />
            </div>
            <div className='modal__row'>
              <div className='modal__title'>
                Показать рейсы на завтра?
              </div>
              <Toggle checked={showNextDayTrips} setChecked={setShowNextDayTrips} />
            </div>
            <div className='modal__row'>
              <div className='modal__title modal__title_link'
                onClick={() => {
                  localStorage.removeItem('remainSeats')
                }}
              >
                Сбросить кэш занятых мест
              </div>
            </div>
            <div className='modal__row'>
              <div className='modal__title modal__title_link' onClick={closeModal}>
                <Link to="/">
                  Вернуться на главную страницу
                </Link>

              </div>

              {/* <Toggle checked={showNextDayTrips} setChecked={setShowNextDayTrips} /> */}
            </div>

          </div>
          :
          <div className='modal__content'>
            <div className='modal__title'>
              Информация о бронировании:
            </div>
            <div className='modal__items'>
              {isLoading ? 'Загрузка...' :

                otherTodayOrders.length > 0 ?
                  otherTodayOrders
                    .sort((a, b) => {
                      return a.startStopId > b.startStopId
                    })
                    .map((order) => (getPassengers(order, false)))
                  :
                  <div className='modal__item'>
                    Нет заявок
                  </div>
              }

            </div>

          </div>
        }
      </Modal>}
    </div>
  );
};

export default PassengerCards;
