import React, { useState } from 'react';
import './calendar.css';
import { useDispatch, useSelector } from 'react-redux';
import { changeDate, selectDate, changeSelectedStop } from '../../features/tripDetails/tripDetailsSlice';

function Calendar({ limitation = true }) {
  const currentDate = new Date();
  const [currentMonth, setCurrentMonth] = useState(currentDate.getMonth());
  const [currentYear, setCurrentYear] = useState(currentDate.getFullYear());
  const selectedDate = useSelector(selectDate);

  const dispatch = useDispatch();

  const months = [
    'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
    'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь',
  ];

  const daysOfWeek = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

  const prevMonth = (event) => {
    event.stopPropagation();
    setCurrentMonth((prevMonth) => {
      if (prevMonth === 0) {
        setCurrentYear((prevYear) => prevYear - 1);
        return 11;
      } else {
        return prevMonth - 1;
      }
    });
  };

  const nextMonth = (event) => {
    event.stopPropagation();
    setCurrentMonth((prevMonth) => {
      if (prevMonth === 11) {
        setCurrentYear((prevYear) => prevYear + 1);
        return 0;
      } else {
        return prevMonth + 1;
      }
    });
  };

  const getDaysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const renderCalendar = () => {
    const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay() || 7;
    const daysInMonth = getDaysInMonth(currentMonth, currentYear);
    const prevMonthDays = getDaysInMonth(currentMonth - 1, currentYear);

    let daysToDisplay = [];

    for (let i = 0; i < firstDayOfMonth - 1; i++) {
      daysToDisplay.unshift({
        value: prevMonthDays - i,
        isCurrentMonth: false
      });
    }

    for (let i = 1; i <= daysInMonth; i++) {
      daysToDisplay.push({
        value: i,
        isCurrentMonth: true
      });
    }

    const lastDayOfMonth = new Date(currentYear, currentMonth, daysInMonth).getDay() || 7;
    const remainingDays = 7 - lastDayOfMonth;

    for (let i = 1; i <= remainingDays; i++) {
      daysToDisplay.push({
        value: i,
        isCurrentMonth: false
      });
    }

    const daysToDisplayMergedSplit = [];

    for (let i = 0; i < daysToDisplay.length; i += 7) {
      daysToDisplayMergedSplit.push(daysToDisplay.slice(i, i + 7));
    }

    function handleDayClick(value, isCurrentMonth) {
      let month = currentMonth;
      let year = currentYear;

      if (!isCurrentMonth) {
        if (value > 15) {
          month -= 1;
          if (month < 0) {
            month = 11;
            year -= 1;
          }
        } else {
          month += 1;
          if (month > 11) {
            month = 0;
            year += 1;
          }
        }
      }

      dispatch(changeSelectedStop(''));
      dispatch(changeDate(`${value}.${month + 1}.${year}`));
    }

    function getNext7Days() {
      let datesArray = [];
      let tempDate = new Date(currentDate);

      for (let i = 0; i < 7; i++) {
        let nextDate = new Date(tempDate);
        nextDate.setDate(tempDate.getDate() + i);
        let day = nextDate.getDate();
        let month = nextDate.getMonth();
        let year = nextDate.getFullYear();
        datesArray.push({ day, month, year });
      }

      return datesArray;
    }

    function next7DaysCheck(day, isCurrentMonth) {
      if (!limitation) return true;

      const next7DaysArr = getNext7Days();

      return next7DaysArr.some(nextDay => {
        const dayMonth = isCurrentMonth ? currentMonth : (day.value > 15 ? currentMonth - 1 : currentMonth + 1);
        const dayYear = (dayMonth < 0) ? currentYear - 1 : (dayMonth > 11) ? currentYear + 1 : currentYear;
        return nextDay.day === day.value && nextDay.month === dayMonth && nextDay.year === dayYear;
      });
    }

    return (
      <div className='calendar-days'>
        {daysToDisplayMergedSplit.map((row, index) => (
          <div key={`row-${index}`} className='calendar-row'>
            {row.map((day) => {
              const isDisabled = (currentMonth === currentDate.getMonth() && day.isCurrentMonth && day.value < currentDate.getDate()) ||
                !next7DaysCheck(day, day.isCurrentMonth);

              let dayMonth = currentMonth;
              let dayYear = currentYear;

              if (!day.isCurrentMonth) {
                if (day.value > 15) {
                  dayMonth -= 1;
                  if (dayMonth < 0) {
                    dayMonth = 11;
                    dayYear -= 1;
                  }
                } else {
                  dayMonth += 1;
                  if (dayMonth > 11) {
                    dayMonth = 0;
                    dayYear += 1;
                  }
                }
              }

              const isSelected = selectedDate === `${day.value}.${dayMonth + 1}.${dayYear}`;

              return (
                <div
                  key={`row-${index}-val-${day.value}`}
                  className={`calendar-day ${isSelected ? 'calendar-day-selected' : ''} ${isDisabled ? 'calendar-day-disabled' : ''}`}
                  onClick={isDisabled ? () => {} : () => { handleDayClick(day.value, day.isCurrentMonth) }}
                >
                  {day.value}
                </div>
              )
            })}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="calendar">
      <div className='calendar-header'>
        <div className='calendar-header-title'>
          <div className='calendar-arrow' onClick={prevMonth}>&#8592;</div>
          <h1 className='calendar-title'>{months[currentMonth]} {currentYear}</h1>
          <div className='calendar-arrow' onClick={nextMonth}>&#8594;</div>
        </div>
        <div className="days-of-week">
          {daysOfWeek.map((day) => (
            <div key={day} className="day-of-week">{day}</div>
          ))}
        </div>
      </div>
      <div>
        {renderCalendar()}
      </div>
    </div>
  );
}

export default Calendar;
