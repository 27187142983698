import { $authHost, $host } from "./index";


export const getData = async () => {
    const { data } = await $host.get('api/contact/data');
    return data;
};

export const sendEmail = async (emailData) => {
    const { data } = await $host.post('api/contact/send-email', emailData);
    return data;
};