import './admin.css'
import React, {useState, useEffect} from 'react'
import RoutesModal from './modals/routesModal';
import StopsModal from './modals/stopsModal';
import EmployeesModal from './modals/employeesModal';
import TripsModal from './modals/tripsModal';
import BusesModal from './modals/busesModal';
import UsersModal from './modals/usersModal';
import SettingsModal from './modals/settingsModal';
import BlacklistModal from './modals/blacklistModal';
import StatisticsModal from './modals/statisticsModal';
import { useDispatch, useSelector } from 'react-redux';

import { changeRoutes } from '../../features/routes/routesSlice';
import { selectRole } from '../../features/user/userSlice'
import { getAllRoutes } from '../../http/routesApi';
import { useNavigate } from 'react-router-dom';
import OrdersModal from './modals/ordersModal';


function Admin() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isAdmin = useSelector(selectRole) === 'ADMIN';
    const [isShowRoutes, setIsShowRoutes] = useState(false);
    const [isShowStops, setIsShowStops] = useState(false);
    const [isShowEmployees, setIsShowEmployees] = useState(false);
    const [isShowTrips, setIsShowTrips] = useState(false);
    const [isShowBuses, setIsShowBuses] = useState(false);
    const [isShowUsers, setIsShowUsers] = useState(false);
    const [isShowBlacklist, setIsShowBlacklist] = useState(false);
    const [isShowOrders, setIsShowBlacklistOrders] = useState(false);
    const [isShowSettings, setIsShowSettings] = useState(false);
    const [isShowStatistics, setIsShowStatistics] = useState(false);

    useEffect(() => {
        console.log(isAdmin);
        if (!isAdmin) return
        getAllRoutes().then(data => {dispatch(changeRoutes(data))})   
    }, [])

    function handleClickRoutes() {
        setIsShowRoutes(!isShowRoutes);
        setIsShowStops(false);   
        setIsShowEmployees(false);
        setIsShowTrips(false);
        setIsShowBuses(false)   
        setIsShowUsers(false);
        setIsShowBlacklist(false);
        setIsShowBlacklistOrders(false)
        setIsShowSettings(false)
        setIsShowStatistics(false)
    }
    function handleClickStops() {
        setIsShowRoutes(false);
        setIsShowStops(!isShowStops);  
        setIsShowEmployees(false);   
        setIsShowTrips(false);   
        setIsShowBuses(false)   
        setIsShowUsers(false);
        setIsShowBlacklist(false);
        setIsShowBlacklistOrders(false)
        setIsShowSettings(false)
        setIsShowStatistics(false)
    }
    function handleClickEmployees() {
        setIsShowRoutes(false);
        setIsShowStops(false);  
        setIsShowEmployees(!isShowEmployees);   
        setIsShowTrips(false);
        setIsShowBuses(false)   
        setIsShowUsers(false);
        setIsShowBlacklist(false);
        setIsShowBlacklistOrders(false)
        setIsShowSettings(false)
        setIsShowStatistics(false)
    }
    function handleClickTrips() {
        setIsShowRoutes(false);
        setIsShowStops(false);  
        setIsShowEmployees(false); 
        setIsShowTrips(!isShowTrips);  
        setIsShowBuses(false);
        setIsShowUsers(false);
        setIsShowBlacklist(false);
        setIsShowBlacklistOrders(false)
        setIsShowSettings(false)
        setIsShowStatistics(false)
    }
    function handleClickBuses() {
        setIsShowRoutes(false);
        setIsShowStops(false);  
        setIsShowEmployees(false); 
        setIsShowTrips(false);
        setIsShowBuses(!isShowBuses);
        setIsShowUsers(false);   
        setIsShowBlacklist(false);
        setIsShowBlacklistOrders(false)
        setIsShowSettings(false)   
        setIsShowStatistics(false)  
    }
    function handleClickUsers() {
        setIsShowRoutes(false);
        setIsShowStops(false);  
        setIsShowEmployees(false); 
        setIsShowTrips(false);            
        setIsShowBuses(false);
        setIsShowUsers(!isShowUsers);
        setIsShowBlacklist(false);
        setIsShowBlacklistOrders(false)
        setIsShowSettings(false)
        setIsShowStatistics(false)
    }
    function handleClickBlacklist() {
        setIsShowRoutes(false);
        setIsShowStops(false);  
        setIsShowEmployees(false); 
        setIsShowTrips(false);            
        setIsShowBuses(false);
        setIsShowUsers(false);
        setIsShowBlacklist(!isShowBlacklist);
        setIsShowBlacklistOrders(false)
        setIsShowSettings(false)
        setIsShowStatistics(false)
    }
    function handleClickOrders() {
        setIsShowRoutes(false);
        setIsShowStops(false);  
        setIsShowEmployees(false); 
        setIsShowTrips(false);            
        setIsShowBuses(false);
        setIsShowUsers(false);
        setIsShowBlacklist(false);
        setIsShowBlacklistOrders(!isShowOrders)
        setIsShowSettings(false)
        setIsShowStatistics(false)
    }

    function handleClickSettings() {
        setIsShowRoutes(false);
        setIsShowStops(false);  
        setIsShowEmployees(false); 
        setIsShowTrips(false);            
        setIsShowBuses(false);
        setIsShowUsers(false);
        setIsShowBlacklist(false);
        setIsShowBlacklistOrders(false)
        setIsShowSettings(!isShowSettings)
        setIsShowStatistics(false)
    }
    function handleClickStatistics() {
        setIsShowRoutes(false);
        setIsShowStops(false);  
        setIsShowEmployees(false); 
        setIsShowTrips(false);            
        setIsShowBuses(false);
        setIsShowUsers(false);
        setIsShowBlacklist(false);
        setIsShowBlacklistOrders(false)
        setIsShowSettings(false)
        setIsShowStatistics(!isShowStatistics)
    }
    function handleCloseAll() {
        setIsShowRoutes(false);
        setIsShowStops(false);  
        setIsShowEmployees(false); 
        setIsShowTrips(false);    
        setIsShowBuses(false);
        setIsShowUsers(false);
        setIsShowBlacklist(false);
        setIsShowBlacklistOrders(false)
        setIsShowSettings(false)
        setIsShowStatistics(false)
    }

    return (
        <div className="container">
            <div className='admin__header'>
                <button className='admin__header-link' onClick={()=>{navigate('/')}}>На главную</button>
            </div>
            {isAdmin && <div className='admin__main-wrap'>
                <div onClick={handleClickTrips} className="admin__main-btn">Рейсы</div>                   
                <div onClick={handleClickStops} className="admin__main-btn">Остановки</div>
                <div onClick={handleClickEmployees} className="admin__main-btn">Сотрудники</div>                
                <div onClick={handleClickBuses} className="admin__main-btn">Маршрутки</div>                             
                <div onClick={handleClickRoutes} className="admin__main-btn">Маршруты</div>
                <div onClick={handleClickUsers} className="admin__main-btn">Все пользователи</div>
                <div onClick={handleClickBlacklist} className="admin__main-btn">Черный список</div>
                <div onClick={handleClickOrders} className="admin__main-btn">Брони</div>
                <div onClick={handleClickStatistics} className="admin__main-btn">Статистика</div>
                <div onClick={handleClickSettings} className="admin__main-btn">Настройки</div>

                {isShowRoutes && <RoutesModal handleCloseAll={handleCloseAll}/>}
                {isShowStops && <StopsModal handleCloseAll={handleCloseAll} />}
                {isShowEmployees && <EmployeesModal handleCloseAll={handleCloseAll}/>}
                {isShowTrips && <TripsModal handleCloseAll={handleCloseAll} />}
                {isShowBuses && <BusesModal handleCloseAll={handleCloseAll} />}
                {isShowUsers && <UsersModal handleCloseAll={handleCloseAll} />}
                {isShowBlacklist && <BlacklistModal handleCloseAll={handleCloseAll} />}
                {isShowOrders && <OrdersModal handleCloseAll={handleCloseAll} />}
                {isShowSettings && <SettingsModal handleCloseAll={handleCloseAll} />}
                {isShowStatistics && <StatisticsModal handleCloseAll={handleCloseAll} />}
            </div>}            
        </div>
    )
}

export default Admin;