import './usersModal.css'
import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAllUsers, unblockUser } from '../../../http/userApi';


function BlacklistModal(props) {
    const dispatch = useDispatch()  
    const {handleCloseAll} = props;
    const [allUsers, setAllUsers] = useState('');    
    
    useEffect(() => {
        getAllUsers('USER').then(data => {setAllUsers(data)})
    }, [])

    function handleUnblockUser(user) {
        unblockUser({phoneNumber: user.phoneNumber}).then(data => {alert(data.message)});
    }


    return (       
        <div className='admin__modal-wrapper'>  
                          
            <div className='admin__modal-container'>            
                <div className='admin__modal-close' onClick={() => handleCloseAll()}></div>
                
                {
                    allUsers && allUsers.map(user => {
                        return user.isBlocked && <div key={user.id} className='users__modal-elem-wrap'>
                            <div>
                                <p className='users__modal-elem-item'>Имя: {user.name}</p>
                                <p className='users__modal-elem-item'>Номер телефона: {user.phoneNumber}</p>
                                <p className='users__modal-elem-item'>Баллов: {user.points}</p>
                                <p className='users__modal-elem-item'>Почему заблокирован: {user.blockComment}</p>
                            </div>
                            <div className='admin__modal-btn admin__modal-danger' onClick={() => handleUnblockUser(user)}>Разблокировать</div>                            
                        </div>
                    })                  
                }
            </div> 
        </div>                    
    )
}

export default BlacklistModal;