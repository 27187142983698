import React, { useEffect } from 'react';
import './header.css';
import { useSelector, useDispatch } from 'react-redux';
import {
    selectSeatsRemain,
    increaseSeatsRemain,
    decreaseSeatsRemain
} from '../../features/driverDetails/driverDetailsSlice';
import { selectTripId } from '../../features/tripDetails/tripDetailsSlice';

function Header({darkTheme, passengersFromList}) {
    const seatsRemain = useSelector(selectSeatsRemain)
    const tripId = useSelector(selectTripId)
    const dispatch = useDispatch();

    const inHandler = () => {
        if (seatsRemain <= 0) return
        localStorage.setItem('remainSeats', JSON.stringify({tripId: tripId, remainingSeats: seatsRemain - 1}))
        dispatch(decreaseSeatsRemain());
    }

    const outHandler = () => {
        if (seatsRemain >= 15) return
        localStorage.setItem('remainSeats', JSON.stringify({tripId: tripId, remainingSeats: seatsRemain + 1}))
        dispatch(increaseSeatsRemain())
    }

    useEffect(()=>{},[seatsRemain])

    return (
        <div className={`driver__container ${darkTheme ? 'dark' : ''}`}>
            <div className="driver__header__content">
                <button onClick={outHandler} className='driver__header__btn'>-1</button>
                <div className='driver__header__info'>Мест Осталось: <span>{seatsRemain  - passengersFromList}</span></div> {/*  */}
                <button onClick={inHandler} className='driver__header__btn'>+1</button>
            </div>
        </div>
    );
}


export default Header;
