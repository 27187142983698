import { $authHost, $host } from "./index";

export const createTrip = async (trip) => {
    const {data} = await $authHost.post('api/trip/create', trip)
    return data
}

export const deleteTrip = async (trip) => {
    const {data} = await $authHost.post('api/trip/delete', trip)
    return data
}

export const getByDay = async (dateStart, dateNext, routeId) => {
    const {data} = await $host.get('api/trip/getByDay', {params: {dateStart, dateNext, routeId}})
    return data
}

export const getByBus = async (busId) => {
    const {data} = await $host.get('api/trip/getByBus', {params: {busId}})
    return data
}

export const updateSeatsWithList = async (id, withListSeats) => {
    const { data } = await $authHost.put('api/trip/updateSeatsWithList', { id, withListSeats });
    return data;
}

export const getTripById = async (id) => {
    const { data } = await $host.get(`api/trip/getById/${id}`);
    return data;
}