import React, {useEffect} from 'react';
import '../../app.css';
import Footer from '../../components/Footer/Footer';
import Contact from '../../components/contact/Contact';
import Header from "../../components/header/Header";
import Info from '../../components/info/Info';
import Reservation from '../../components/reservation/Reservation';
import ReservationTickets from '../../components/reservationTickets/ReservationTickets';
import { useDispatch, useSelector } from 'react-redux';
import { changeRoutes } from '../../features/routes/routesSlice';
import { getAllRoutes } from '../../http/routesApi';
import { selectSelectedStop } from '../../features/tripDetails/tripDetailsSlice';


function User() {
    const dispatch = useDispatch();
    const stop = useSelector(selectSelectedStop);

    useEffect(() => {
        // const formData = new FormData()
        // formData.append('routeId', route.id)

        getAllRoutes().then(data => {dispatch(changeRoutes(data))})                      
    }, [])

    return (
        <div className='page__user'>
            <header className="header">        
                <Header />  
            </header>
            <div className='info'>
                <Info />
            </div>
            <div className='reservation'>
                <Reservation />
            </div>
            <div className='reservationTickets'>
                {stop && <ReservationTickets />}
            </div>
            <div className='contact'>
                <Contact />
            </div>
            <div className='footer'>
                <Footer />
            </div>
        </div>
    )
}

export default User;