import { $authHost, $host } from "./index";


export const getAllSet = async () => {
    const { data } = await $host.get('api/settings/getAllSet');
    return data;
};

export const changeSet = async (typeField, valueField) => {
    const { data } = await $authHost.put('api/settings/changeSet', { typeField, valueField });
    return data;
};