import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../features/user/userSlice';
import tripDetailsReducer from '../features/tripDetails/tripDetailsSlice'
import driverDetailsReducer from '../features/driverDetails/driverDetailsSlice';
import routesSlice from '../features/routes/routesSlice';
import stopsSlice from '../features/stops/stopsSlice';

export default configureStore({
  reducer: {
    user: userReducer,
    tripDetails: tripDetailsReducer,
    driverDetails: driverDetailsReducer,
    routes: routesSlice,
    stops: stopsSlice,
  },
});
