import React, {useState, useEffect, useRef} from 'react';
import { useSelector } from 'react-redux';
import { 
    selectDate
} from '../../features/tripDetails/tripDetailsSlice';
import Calendar from '../Calendar/Calendar';


function DateCard() {
    const currentDate = useSelector(selectDate);    
    const [isToShow, setIsToShow] = useState(false);
    const containerRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (containerRef.current && !containerRef.current.contains(event.target)) {
            setIsToShow(false);
          }
        };
    
        document.addEventListener('click', handleClickOutside);
    
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
      }, []);

      function getFormattedDate() {
        let formattedCurrentDate = currentDate.split('.');
        if (formattedCurrentDate[0].length === 1) {
          formattedCurrentDate[0] = `0${formattedCurrentDate[0]}`
        }    
        if (formattedCurrentDate[1].length === 1) {
          formattedCurrentDate[1] = `0${formattedCurrentDate[1]}`
        }    
        return formattedCurrentDate.join('.');
      }

    return (
        <>
            <div ref={containerRef} onClick={() => {setIsToShow(!isToShow)}} className='additional__container'>
                <div className='additional_title'>Выберите дату</div>
                <div className='additional-value'>{getFormattedDate()}</div>            
            </div> 
            {isToShow && <Calendar />}
        </>
    );
}

export default DateCard;
