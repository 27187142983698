import { $authHost, $host } from "./index";

export const createStop = async (stop) => {
    const {data} = await $authHost.post('api/stop/create', stop)
    return data
}

export const deleteStop = async (stop) => {
    const {data} = await $authHost.post('api/stop/delete', stop)
    return data
}

export const changeStop = async (stop) => {
    const {data} = await $authHost.post('api/stop/change', stop)
    return data
}

export const getAllStops = async (routeId) => {
    const {data} = await $host.get('api/stop/getAll', { params: { routeId } })
    return data
}