import './info.css'

function Info() {
  return (
    <div className="container">
        <div className='info__content'>
            <div className='info__main'>
                <h1 className='info__main-name'>606tk Поставы-Минск</h1>
                <p className='info__main-description'>Система бронирования мест</p>
            </div>
        </div>
    </div>
  );
}

export default Info;
