import { useEffect, useState } from "react";
import Header from "./Header";
import ReservationTickets from './ReservationTickets';

function Driver() {
    const [darkTheme, setDarkTheme] = useState(false);
    const [seatsCount, setSeatsCount] = useState(15);
    const [remainSeatsCount, setRemainSeatsCount] = useState(15);
    const [passengersFromList, setPassengersFromList] = useState(0);

    useEffect(()=>{
        if(!localStorage.getItem('darkTheme')){
            localStorage.setItem('darkTheme', darkTheme)
        } else {
            setDarkTheme(JSON.parse(localStorage.getItem('darkTheme')))
        }

        // if(!localStorage.getItem('remainSeats')){
        //     localStorage.setItem('remainSeats', remainSeatsCount)
        // } else {
        //     setRemainSeatsCount(JSON.parse(localStorage.getItem('remainSeats')))
        // }
    }, [])

    useEffect(()=>{
            localStorage.setItem('darkTheme', darkTheme)
    }, [darkTheme])

    // useEffect(()=>{
    //     localStorage.setItem('remainSeats', remainSeatsCount)
    // }, [remainSeatsCount])


    return (
        <div className="page__driver">
            <header className="driver__header">        
                <Header
                darkTheme={darkTheme}
                passengersFromList={passengersFromList}
                
                />  
            </header>
            <div className='reservationTickets'>
                <ReservationTickets 
                darkTheme={darkTheme}
                setDarkTheme={setDarkTheme}
                seatsCount={seatsCount}
                setSeatsCount={setSeatsCount}
                remainSeatsCount={remainSeatsCount}
                setRemainSeatsCount = {setRemainSeatsCount}
                setPassengersFromList={setPassengersFromList}
                />
            </div>
        </div>
    )
}

export default Driver;