import './modal.css'
import React, {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    selectRoutes,
    changeRoutes,
} from '../../../features/routes/routesSlice'
import { changeRoute, createRoute, getAllRoutes } from '../../../http/routesApi';

function RoutesModal(props) {
    const dispatch = useDispatch()
    const routes = useSelector(selectRoutes)    
    const [routesLocal, setRoutesLocal] = useState(routes)
    const [createRouteVal, setCreateRouteVal] = useState('');
    const {handleCloseAll} = props;

    function handleRouteChange(routeId, newValue) {        
        setRoutesLocal(prevRoutes => {
            const updatedRoutes = prevRoutes.map(route => {
                if (route.id === routeId) {
                    return { ...route, name: newValue };
                }
                return route;
            });
            return updatedRoutes;
        });
    }

    function handleRouteSave(newRoute) {
        const candidateToChangeNew = routesLocal.find(route => route.id === newRoute.id);
        const candidateToChangeOld = routes.find(route => route.id === newRoute.id);
        if (candidateToChangeNew.name === candidateToChangeOld.name) {
            alert('Ничего не изменено');
            return
        }

        try {
            changeRoute({id: newRoute.id, name: newRoute.name}).then(data => {
                alert('Маршрут изменен успешно!');
            })
            // dispatch(changeRoutes(routesLocal));            
        } catch (error) {
            alert('Что-то пошло не так...');
        }        
    }

    function handleRouteCreate() {
        createRoute({name: createRouteVal}).then(date => alert('Маршрут создан успешно'));
        getAllRoutes().then(data => {setRoutesLocal(data); dispatch(changeRoutes(data))})
    }


    return (       
        <div className='admin__modal-wrapper'>
            
            <div className='admin__modal-container'>
                <div className='admin__modal-close' onClick={() => handleCloseAll()}></div>
                <div className='admin___modal-change no-border'>
                <h3 className='admin___modal-title'>Изменить название машрута</h3>
                    {
                        routesLocal[0] && routesLocal.map(route => {
                            return <div key={route.id} className='admin___modal-wrap'>
                                <input
                                    type="text"
                                    value={route.name}
                                    onChange={e => handleRouteChange(route.id, e.target.value)}
                                    placeholder="Маршрут"
                                    className='admin_modal-input'
                                />
                                <div className='admin__modal-btn admin__modal-success' onClick={() => handleRouteSave(route)}>&#10003;</div>                                
                            </div>
                        })
                    }                    
                </div>
            </div> 
        </div>                    
    )
}

export default RoutesModal;