import { $authHost, $host } from "./index";

export const createBus = async (bus) => {
    const {data} = await $authHost.post('api/bus/create', bus)
    return data
}

export const deleteBus = async (bus) => {
    const {data} = await $authHost.post('api/bus/delete', bus)
    return data
}

export const getAllBuses = async () => {
    const {data} = await $host.get('api/bus/getAll')
    return data
}

export const getBus = async (driverId) => {
    const {data} = await $host.get('api/bus/getOne', { params: { driverId }})
    return data
}

export const changeDriver = async (bus) => {
    const {data} = await $authHost.post('api/bus/changeDriver', bus)
    return data
}

