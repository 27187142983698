import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    id: '',
    name: '',
    number: '',
    bonuses: 0,
    isBlocked: false,
    role: ""
  },
  reducers: {
    changeId: (state, action) => {
      state.id = action.payload
    },
    changeName: (state, action) => {
      state.name = action.payload
    },
    changeNumber: (state, action) => {
      state.number = action.payload
    },
    increaseBonuses: (state) => {
      state.bonuses += 33
    },
    decreaseBonuses: (state) => {
      state.bonuses -= 33
    },
    changeBonuces: (state, action) => {
      state.bonuses = action.payload
    },
    changeRole: (state, action) => {
      state.role = action.payload
    },
    changeIsBlocked: (state, action) => {
      state.isBlocked = action.payload
    },
  },
})

export const { changeId, changeName, changeNumber, changeRole, increaseBonuses, decreaseBonuses, changeBonuces, changeIsBlocked } = userSlice.actions


export const selectId = (state) => state.user.id
export const selectName = (state) => state.user.name
export const selectNumber = (state) => state.user.number
export const selectBonuses = (state) => state.user.bonuses
export const selectRole = (state) => state.user.role
export const selectIsBlocked = (state) => state.user.isBlocked

export default userSlice.reducer
