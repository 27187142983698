import './modal.css'
import './bus.css'
import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createBus, deleteBus, getAllBuses, changeDriver } from '../../../http/busesApi';
import { getAllUsers } from '../../../http/userApi';


function BusesModal(props) {
    const dispatch = useDispatch()
    const [buses, setBuses] = useState('');
    const [drivers, setDrivers] = useState('');
    const [newBusName, setNewBusName] = useState('');
    const [newBusNumber, setNewBusNumber] = useState('');
    const [driverId, setDriverId] = useState('');
    const [changeDriverId, setChangeDriverId] = useState('');
    const [changeBusId, setChangeBusId] = useState('');
    const {handleCloseAll} = props;


    useEffect(() => {
        const role = 'DRIVER'
        getAllBuses().then(data => {setBuses(data)})
        getAllUsers(role).then(data => {setDrivers(data)});
    }, [])    
    console.log(drivers)

    function handleBusDelete(bus) {        
        deleteBus({id: bus.id}).then(data => {getAllBuses().then(data => {setBuses(data)}); alert('Маршрутка удалена успешно')})        
    }

    function handleChangeDriverId(driver) {
        setDriverId(driver.id)
    }

    function handleAddNewBus() {
        createBus({name: newBusName, number: newBusNumber, userId: driverId}).then(data => {
            getAllBuses().then(data => {setBuses(data)})
            alert('Маршрутка создана успешно')
        })        
    }

    function handleUpdateDriver() {
        changeDriver({id: changeBusId, userId: changeDriverId}).then(data => {console.log(data)})
    }

    return (       
        <div className='admin__modal-wrapper'> 
                    
            <div className='admin__modal-container'>    
                <div className='admin__modal-close' onClick={() => handleCloseAll()}></div>              
                <div className='admin___modal-change'>
                    {
                        buses && buses.map(bus => {
                            return <div key={bus.id} className='admin___modal-wrap'>
                                <div>{bus.name}</div>
                                <div className='admin__modal-btn admin__modal-danger' onClick={() => handleBusDelete(bus)}>&#10006;</div>
                            </div>
                        })                    
                    }
                </div>
                <div className='admin___modal-add'>
                    <h3 className='admin___modal-title'>Добавить маршрутку</h3>
                    <div className='admin___modal-wrap'>
                        <div className='admin___modal-input_wrap'>
                            <input
                                type="text"
                                value={newBusName}
                                onChange={e => setNewBusName(e.target.value)}
                                placeholder="Название"
                                className='admin_modal-input'
                            />
                            <input
                                type="text"
                                value={newBusNumber}
                                onChange={e => setNewBusNumber(e.target.value)}
                                placeholder="Номер"
                                className='admin_modal-input admin__modal_bus-input-short'
                            />
                        </div>
                        <div className='admin__modal-btn admin__modal-success' onClick={() => handleAddNewBus()}>&#10003;</div>
                    </div>
                    <div className='admin__modal-checkbox-wrap'>
                        <p className='admin__modal-checkbox_text'>Выберите водителя маршрутки:</p>
                            {
                                drivers && drivers.map(driver => {
                                    return <div className='admin___modal_buses-checkbox-w' key={driver.id}>
                                        <div>{driver.name} {driver.phoneNumber}</div>
                                        <input
                                            type="checkbox"
                                            checked={driver.id === driverId}
                                            onChange={() => {handleChangeDriverId(driver)}}
                                        />
                                    </div>
                                })
                            }             
                    </div>    
                    <h3 className='admin___modal-title'>Изменить водителя маршрутки</h3>
                    <div className='admin__modal-change_driver-wrap'>
                            {
                                drivers && drivers.map(driver => {
                                    return <div className='admin___modal_buses-checkbox-w' key={driver.id}>
                                        <div>{driver.name} {driver.phoneNumber}</div>
                                        <input
                                            type="checkbox"
                                            checked={driver.id === changeDriverId}
                                            onChange={() => {setChangeDriverId(driver.id)}}
                                        />
                                    </div>
                                })

                            }             
                    </div>   
                    <div className='admin__modal-change_driver-wrap'>
                            {
                                changeDriverId && buses.map(bus => {
                                    return <div className='admin___modal_buses-checkbox-w' key={bus.id}>
                                        <div>{bus.name}</div>
                                        <input
                                            type="checkbox"
                                            checked={bus.id === changeBusId}
                                            onChange={() => {setChangeBusId(bus.id)}}
                                        />
                                    </div>
                                })
                                
                            }             
                    </div>          
                    <div className='admin__modal-change_driver-wrap'>
                        {
                            changeBusId && 
                                <div className='admin__modal-btn admin__modal-success' onClick={handleUpdateDriver}>&#10003;</div>
                        }
                    </div>        
                </div>
            </div> 
        </div>                    
    )
}

export default BusesModal;