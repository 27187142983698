import { createSlice } from '@reduxjs/toolkit'

export const stopsSlice = createSlice({
  name: 'stops',
  initialState: {
    stops: [],
  },
  reducers: {
    changeStops: (state, action) => {
      state.stops = action.payload
    },
  },
})

export const { changeStops } = stopsSlice.actions


export const selectStops = (state) => state.stops.stops

export default stopsSlice.reducer
