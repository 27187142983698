import { $authHost, $host } from "./index";

export const createOrder = async (order) => {
    const {data} = await $host.post('api/subRoute/create', order)
    return data
}

export const deleteOrder = async (order) => {
    const {data} = await $authHost.post('api/subRoute/delete', order)
    return data
}

export const updateOrder = async (order) => {
    const {data} = await $authHost.post('api/subRoute/update', order)
    return data
}

export const getAllByDay = async (dateNow, dateNext) => {
    const {data} = await $host.get('api/subRoute/getByDay', {params: {dateNow, dateNext}})
    return data
}

export const getAllByTrip = async (tripId) => {
    const {data} = await $host.get('api/subRoute/getByTrip', { params: { tripId } })
    return data
}

export const getAllByUser = async (userId) => {
    const {data} = await $host.get('api/subRoute/getByUser', { params: { userId } })
    return data
}

export const getAllSuccessful = async () => {
    const {data} = await $host.get('api/subRoute/getTotalSeatsWithStatusSel')
    return data
}

export const getSuccessfulThisMonth = async () => {
    const {data} = await $host.get('api/subRoute/getMonthlySeatsWithStatusSel')
    return data
}