import './modal.css'
import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    selectStops, changeStops
} from '../../../features/stops/stopsSlice'
import { selectRoutes } from '../../../features/routes/routesSlice';
import { getAllStops, changeStop, deleteStop, createStop } from '../../../http/stopsApi';


function StopsModal(props) {
    const dispatch = useDispatch();
    const stops = useSelector(selectStops);
    const routes = useSelector(selectRoutes);
    const [stopsLocal, setStopsLocal] = useState([]);
    const [isSortToA, setIsSortToA] = useState(false);
    const [isSortToB, setIsSortToB] = useState(false);
    const [newStopName, setNewStopName] = useState('');
    const [newStopTimeOffSet, setNewStopTimeOffSet] = useState('');
    const [newStopRouteId, setNewStopRouteId] = useState('');
    const [newStopToA, setNewStopToA] = useState(false);
    const [newStopToB, setNewStopToB] = useState(false);
    const { handleCloseAll } = props;

    useEffect(() => {
        setStopsLocal(stops);
    }, [stops]);

    const handleSortToA = async () => {
        if (routes[0] && routes[0].id) {
            const data = await getAllStops(routes[0].id);
            dispatch(changeStops(data));
            setStopsLocal(data);
            setIsSortToB(false);
            setIsSortToA(!isSortToA);
        }
    };

    const handleSortToB = async () => {
        if (routes[1] && routes[1].id) {
            const data = await getAllStops(routes[1].id);
            dispatch(changeStops(data));
            setStopsLocal(data);
            setIsSortToA(false);
            setIsSortToB(!isSortToB);
        }
    };

    function handleNameChange(stopId, newValue) {
        setStopsLocal(prevStops => {
            const updatedStops = prevStops.map(stop => {
                if (stop.id === stopId) {
                    return { ...stop, name: newValue };
                }
                return stop;
            });
            return updatedStops;
        });
    }

    function handleTimeOffSetChange(stopId, newValue) {
        setStopsLocal(prevStops => {
            const updatedStops = prevStops.map(stop => {
                if (stop.id === stopId) {
                    return { ...stop, timeOffSet: newValue };
                }
                return stop;
            });
            return updatedStops;
        });
    }

    function handleStopSave(newStop) {
        const candidateToChangeNew = stopsLocal.find(stop => stop.id === newStop.id);
        const candidateToChangeOld = stops.find(stop => stop.id === newStop.id);
        if (candidateToChangeNew.name === candidateToChangeOld.name && candidateToChangeNew.timeOffSet === candidateToChangeOld.timeOffSet) {
            alert('Ничего не изменено');
            return;
        }

        try {
            changeStop({ id: newStop.id, name: newStop.name, timeOffSet: newStop.timeOffSet }).then(data => {
                let filteredStopsLocal = stopsLocal;
                filteredStopsLocal = filteredStopsLocal.sort((a, b) => a.timeOffSet - b.timeOffSet);
                dispatch(changeStops(filteredStopsLocal));
                alert('Остановка изменена успешно!');
            });
        } catch (error) {
            alert('Что-то пошло не так...');
        }
    }

    function handleCheckboxToA() {
        setNewStopRouteId(1);
        setNewStopToA(true);
        setNewStopToB(false);
    }

    function handleCheckboxToB() {
        setNewStopRouteId(2);
        setNewStopToB(true);
        setNewStopToA(false);
    }

    function handleAddNewStop() {
        createStop({ name: newStopName, timeOffSet: newStopTimeOffSet, routeId: newStopRouteId }).then(data => {
            setNewStopName('');
            setNewStopTimeOffSet('');
            setNewStopRouteId('');
            setNewStopToA(false);
            setNewStopToB(false);
            alert('Остановка добавлена успешно!');
        });
    }

    function handleStopDelete(stop) {
        try {
            deleteStop({ id: stop.id }).then(data => {
                const routeId = isSortToA ? routes[0].id : routes[1].id;
                getAllStops(routeId).then(data => { dispatch(changeStops(data)) });
                alert(`Остановка ${stop.name} удалена успешно!`);
            });
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className='admin__modal-wrapper'>
            <div className='admin__modal-container'>
                <div className='admin__modal-close' onClick={() => handleCloseAll()}></div>
                {routes && routes[0] && routes[1] && (
                    <>
                        <div className='admin__modal-checkbox'>
                            <p className='admin__modal-checkbox_text'>{`${routes[0].name} → ${routes[1].name}`}</p>
                            <input
                                type="checkbox"
                                checked={isSortToB}
                                onChange={handleSortToB}
                            />
                        </div>
                        <div className='admin__modal-checkbox'>
                            <p className='admin__modal-checkbox_text'>{`${routes[1].name} → ${routes[0].name}`}</p>
                            <input
                                type="checkbox"
                                checked={isSortToA}
                                onChange={handleSortToA}
                            />
                        </div>
                    </>
                )}
                <div className='admin___modal-change'>
                    {stopsLocal && stopsLocal.map(stop => (
                        <div key={stop.id} className='admin___modal-wrap'>
                            <div className='admin___modal-input_wrap'>
                                <input
                                    type="text"
                                    value={stop.name}
                                    onChange={e => handleNameChange(stop.id, e.target.value)}
                                    placeholder="Остановка"
                                    className='admin_modal-input'
                                />
                                <input
                                    type="text"
                                    value={stop.timeOffSet}
                                    onChange={e => handleTimeOffSetChange(stop.id, e.target.value)}
                                    placeholder="..."
                                    className='admin_modal-input admin_modal-input-short'
                                />
                            </div>
                            <div className='admin__modal-btn admin__modal-danger' onClick={() => handleStopDelete(stop)}>&#10006;</div>
                            <div className='admin__modal-btn admin__modal-success' onClick={() => handleStopSave(stop)}>&#10003;</div>
                        </div>
                    ))}
                </div>
                <div className='admin___modal-add'>
                    <h3 className='admin___modal-title'>Добавить остановку</h3>
                    <div className='admin___modal-wrap'>
                        <div className='admin___modal-input_wrap'>
                            <input
                                type="text"
                                value={newStopName}
                                onChange={e => setNewStopName(e.target.value)}
                                placeholder="Остановка"
                                className='admin_modal-input'
                            />
                            <input
                                type="text"
                                value={newStopTimeOffSet}
                                onChange={e => setNewStopTimeOffSet(e.target.value)}
                                placeholder="..."
                                className='admin_modal-input admin_modal-input-short'
                            />
                        </div>
                        <div className='admin__modal-btn admin__modal-success' onClick={() => handleAddNewStop()}>&#10003;</div>
                    </div>
                    <div className='admin__modal-checkbox'>
                        <p className='admin__modal-checkbox_text'>{`${routes[0].name} → ${routes[1].name}`}</p>
                        <input
                            type="checkbox"
                            checked={newStopToB}
                            onChange={handleCheckboxToB}
                        />
                    </div>
                    <div className='admin__modal-checkbox'>
                        <p className='admin__modal-checkbox_text'>{`${routes[1].name} → ${routes[0].name}`}</p>
                        <input
                            type="checkbox"
                            checked={newStopToA}
                            onChange={handleCheckboxToA}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StopsModal;
