import { createSlice } from '@reduxjs/toolkit'

export const routesSlice = createSlice({
  name: 'routes',
  initialState: {
    routes: '',
  },
  reducers: {
    changeRoutes: (state, action) => {
      state.routes = action.payload
    },
  },
})

export const { changeRoutes } = routesSlice.actions


export const selectRoutes = (state) => state.routes.routes

export default routesSlice.reducer
