import { $authHost, $host } from "./index";

export const createRoute = async (route) => {
    const {data} = await $authHost.post('api/route/create', route)
    return data
}

export const changeRoute = async (route) => {
    const {data} = await $authHost.post('api/route/change', route)
    return data
}

export const getAllRoutes = async () => {
    const {data} = await $host.get('api/route/getAll')
    return data
}