import React, {useState, useEffect} from 'react'
import {getSMSCredentials} from '../../http/userApi';

function Timer(props) {
    const initialSeconds = 60;
    const [seconds, setSeconds] = useState(initialSeconds);
    const [usernameSms, setUsernameSms] = useState('');
    const [passwordSms, setPasswordSms] = useState('');

    useEffect(() => {
      const fetchSMSCredentials = async () => {
          try {
              const smsCredentials = await getSMSCredentials();
              setUsernameSms(smsCredentials.username);
              setPasswordSms(smsCredentials.password);
          } catch (error) {
              console.error('Error fetching SMS credentials:', error);
          }
      };

      fetchSMSCredentials();
  }, []);

    useEffect(() => {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
      }, 1000);
      return () => clearInterval(interval);
    }, [seconds]);
    
    function sendCodeAgain() {
        const currentNumber = props.number.slice(1);
        const code = props.generateCode();

        const params = new URLSearchParams();
        params.append('username', usernameSms);
        params.append('password', passwordSms);
        params.append('phone', currentNumber);
        params.append('text', `Ваш код подтверждения: ${code}`);

        fetch('https://api.rocketsms.by/simple/send?' + params, {
            method: 'POST',
            mode: 'no-cors',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => {
            setSeconds(initialSeconds);                
        })
        .catch(error => console.error('Error:', error));          
    }
  
    return (
      <>
        {seconds === 0 ? (
          <div className='login-form-sms-wrap'>
            <p className='login-form-sms-req-selected' onClick={sendCodeAgain}>
              <span>Отправить еще</span>
            </p>
          </div>
          
        ) : (
          <div className='login-form-sms-wrap'>
            <p className='login-form-sms-req'>
              <span>Отправить еще</span>
            </p>
          </div>
        )}
        <div className='login-form-sms-wrap'>
          <p className='login-form-sms-req'>через {seconds}</p>
        </div>
      </>
    );
  };
  
  export default Timer;
  